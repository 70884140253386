
import { Component, Vue } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import Page from '@/pages/Page'
import BirthAndStartDateSelectionForm from '@/components/AvailabilitySelector/BirthAndStartDateSelectionForm.vue'
import MultiStateDaySelector from '@/components/MultiStateDaySelector/MultiStateDaySelector.vue'
import { GetWaitingListStatusOutput, Maybe, Proposition, School, SchoolGroup, Service, ServiceKind, ServiceVarietyName, SubscriptionService as SubscriptionServiceModel } from '@/models'
import IPropositionService from '@/services/PropositionService/IPropositionService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { ACTIONS, GETTERS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import PropositionSubscription from '@/models/PropositionSubscription'
import { ROUTES } from '@/router/routes'
import PriceSummary from '../OrderOverviewPage/Content/PriceSummary/PriceSummary.vue'
import PartouRadio from '@/components/PartouComponents/Input/PartouRadio/PartouRadio.vue'
import { determineTypeOfCare } from '@/models/enums/ServiceKind'
import ServiceNotBookable from './ServiceNotBookable/ServiceNotBookable.vue'
import { getStartDateOfCareMinDate, getStartDateOfCareMaxDate } from '@/utils/dateUtils'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import DaySelectionState from '@/models/types/DaySelectionState'
import { sendFastTrackRegistrationAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'

const notPartneredSchoolId = 'not-partnered'

const propositionModule = namespace(NAMESPACES.proposition)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouFloatingCircleButton, PartouButton, BirthAndStartDateSelectionForm, MultiStateDaySelector, PriceSummary, PartouRadio, ServiceNotBookable },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class OrderDirectPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.orderDirect').toString()

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @propositionModule.State(STATE.proposition.waitinglistPropositionStatus)
  waitinglistPropositionStatus?: GetWaitingListStatusOutput

  @propositionModule.Action(ACTIONS.proposition.getPropositionsAsync)
  getPropositionsAsync!: () => Promise<void>

  @propositionModule.Action(ACTIONS.proposition.getPropositionSubscriptionsAsync)
  getPropositionSubscriptionsAsync!: (offer: { offerId: string, productIds?: string[] }) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceAsync)
  setSelectedServiceAsync!: (selectedService: Service | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedPropositionId)
  setSelectedPropositionId!: (selectedPropositionId: string) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolAsync)
  setSelectedSchoolAsync!: (selectedSchool: School | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolGroupAsync)
  setSelectedSchoolGroupAsync!: (selectedSchoolGroup: SchoolGroup | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSubscriptionServiceAsync)
  setSelectedSubscriptionServiceAsync!: (selectedSubscription: Partial<SubscriptionServiceModel>) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @propositionModule.Getter(GETTERS.proposition.getPropositionBySlug)
  getPropositionBySlug!: (serviceSlug: string) => Proposition | undefined

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setPostalCode)
  setPostalCode!: ({ postcode, longitude, latitude }: { postcode: string, longitude: number, latitude: number }) => void

  @propositionModule.Getter(GETTERS.proposition.getPropositionSubscriptionsForOffer)
  getPropositionSubscriptionsForOffer!: (offerId: string) => PropositionSubscription[] | undefined

  @userSelectionModule.Action(ACTIONS.userSelection.setFastTrack)
  setFastTrack!: (fastTrack: boolean) => void

  @userSelectionModule.Action(ACTIONS.userSelection.setServiceVarietyDaysAsync)
  setServiceVarietyDaysAsync!: (input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getIsBSOTransitionAge)
  getIsBSOTransitionAge!: () => boolean

  activeStep = 1
  proposition: Proposition | undefined
  selectedSchool: School | null = null
  schoolGroups: SchoolGroup[] | undefined
  selectedSchoolGroup: SchoolGroup | undefined

  propositionService!: IPropositionService

  showWrongDatesWarning = false
  showCareTypeTransitionWarning = false
  showNoSchoolWarning = false
  showNoSchoolGroupWarning = false
  showNoDatesWarning = false
  showNoDaysWarning = false
  showNotPartneredSchoolAsWarning = false
  datesValid = true

  birthAndStartDateKey = 0
  schoolGroupKey = 0

  async beforeCreate (): Promise<void> {
    this.propositionService = container.get(SERVICE_IDENTIFIERS.IPropositionService)
  }

  created () {
    if (this.isBso && this.schools) {
      this.selectedSchool = this.schools.filter(s => s.id === this.userSelectionFormState.selectedSchool?.id)[0] ?? null
      if (this.selectedSchool) {
        this.selectedSchoolGroup = this.getSchoolGroups(this.selectedSchool.id).filter(sg => sg.id === this.userSelectionFormState.selectedSchoolGroup?.id)[0] ?? null
      }
    }
  }

  async mounted () : Promise<void> {
    this.setFastTrack(true)
    await this.setSelectedServiceAsync(this.service)
    this.setPostalCode({ postcode: this.service.offers[0].postalCode, longitude: this.service.offers[0].longitude, latitude: this.service.offers[0].latitude })
    if (this.isBso && this.selectedSchool) {
      await this.onSchoolPickedAsync(this.selectedSchool.id)
    }
  }

  get service (): Service {
    return this.$route.meta?.service
  }

  get serviceKind (): ServiceKind {
    return this.service.kind === 'DayCare' ? ServiceKind.DayCare : ServiceKind.SchoolCare
  }

  get serviceAddress (): string {
    return this.service.location?.addressLine1 ?? ''
  }

  get isBso (): boolean {
    return this.serviceKind === ServiceKind.SchoolCare
  }

  get schools (): School[] {
    return this.$route.meta?.schools
  }

  get selectedSchoolIsNotPartnered (): boolean {
    return this.selectedSchool?.id === notPartneredSchoolId
  }

  get shouldShowSchoolGroups (): boolean {
    return this.schoolGroups?.length !== undefined && this.schoolGroups.length > 1
  }

  get serviceVarieties (): ServiceVarietyName[] {
    return this.proposition?.offeredServiceVarieties ?? this.service.serviceVarieties.map(sv => sv.name) as ServiceVarietyName[] ?? []
  }

  get schoolId (): string {
    return this.selectedSchool?.id ?? ''
  }

  getSelectedSchoolGroupId (): string {
    return this.selectedSchoolGroup?.id ?? ''
  }

  get isUsingFlexKidsProducts (): boolean {
    return this.service.serviceSettings[0].useFlexkidsProducts ?? false
  }

  getPropositionSubscription (): Maybe<PropositionSubscription> {
    return this.proposition?.propositionSubscriptions?.[0]
  }

  get serviceIsBookable (): boolean {
    return this.service.serviceSettings[0].bookableInSelectionGuide
  }

  get currentProposition (): Proposition | undefined {
    return this.proposition
  }

  get childsFourthBirthday () : Date {
    return moment(this.userSelectionFormState.dateOfBirth).add(4, 'years').toDate()
  }

  getSchoolGroups (schoolId: string): SchoolGroup[] {
    return this.schools.filter(s => s.id === schoolId)[0].schoolGroups
  }

  getStepNumber (number: number) {
    return (number += this.isBso ? 1 : 0)
  }

  async getPropositionAsync () {
    await this.getPropositionsAsync()

    if (!this.service.slug) {
      return
    }

    this.proposition = this.getPropositionBySlug(this.service.slug)
  }

  getAvailabilityData () {
    const availability = this.getDayCheckboxState(true, true)

    if (this.userSelectionFormState.startDateOfDayCare &&
        this.userSelectionFormState.dateOfBirth &&
        !this.showNoDatesWarning &&
        !this.showWrongDatesWarning &&
        !this.showCareTypeTransitionWarning) {
      return availability
    }

    availability.KDV.forEach((item) => {
      item.isDisabled = true
    })

    availability.NSO.forEach((item) => {
      item.isDisabled = true
    })

    availability.VSO.forEach((item) => {
      item.isDisabled = true
    })

    return availability
  }

  isValidDates () {
    const dob = moment(this.userSelectionFormState.dateOfBirth).startOf('day').toDate()
    const start = moment(this.userSelectionFormState.startDateOfDayCare).startOf('day').toDate()

    // Check if the start date is allowed based on the date of birth && check if the dob is possible given the start date
    return moment(moment(getStartDateOfCareMinDate(dob)).startOf('day').toDate()).isSameOrBefore(start) && moment(moment(getStartDateOfCareMaxDate(dob)).startOf('day').toDate()).isSameOrAfter(moment(start))
  }

  async onValidDates () {
    if (!this.userSelectionFormState.dateOfBirth || !this.userSelectionFormState.startDateOfDayCare) {
      this.activeStep = this.getStepNumber(1)
      return
    }

    this.showNoDatesWarning = false
    this.showWrongDatesWarning = false
    this.showCareTypeTransitionWarning = false

    if (!this.isValidDates()) {
      this.activeStep = this.getStepNumber(1)
      return
    }

    if (this.isBso) {
      this.showCareTypeTransitionWarning = this.getIsBSOTransitionAge()
      if (this.showCareTypeTransitionWarning) {
        this.activeStep = this.getStepNumber(1)
        return
      }
    }

    const requiredServiceKind = determineTypeOfCare(this.userSelectionFormState.dateOfBirth, this.userSelectionFormState.startDateOfDayCare)
    this.showWrongDatesWarning = requiredServiceKind !== this.service.kind
    if (this.showWrongDatesWarning) {
      this.activeStep = this.getStepNumber(1)
      return
    }

    if (this.isBso && this.selectedSchool?.id === notPartneredSchoolId) {
      this.activeStep = this.getStepNumber(1)
      return
    }

    await this.getPropositionAsync()

    if (!this.proposition) {
      return
    }

    this.setSelectedPropositionId(this.proposition.id)

    // increase the step if it hasn't already
    if (this.activeStep < this.getStepNumber(2)) {
      this.activeStep = this.getStepNumber(2)
    }

    await this.onDaysChangedAsync()
  }

  async onDaysChangedAsync () {
    this.showNoDaysWarning = false

    if (this.isBso) { // VSO day availability is only returned as true if the NSO day is selected so each day change needs to re-check the VSO status
      await this.getPropositionAsync()
    }

    if (this.hasDaysCheckedForCurrentServiceKind()) {
      this.activeStep = this.getStepNumber(3)
    } else {
      this.activeStep = this.getStepNumber(2)
    }
  }

  hasDaysCheckedForCurrentServiceKind () : boolean {
    const daysVariety = this.serviceKind === ServiceKind.DayCare ? this.userSelectionFormState.daysPerServiceVariety.KDV : this.userSelectionFormState.daysPerServiceVariety.NSO
    return daysVariety.some(d => d.isChecked)
  }

  async onSchoolPickedAsync (schoolId: string) {
    this.schoolGroupKey++

    this.showNotPartneredSchoolAsWarning = false
    this.showNoSchoolWarning = false
    this.selectedSchool = this.schools.filter(s => s.id === schoolId)[0]
    await this.setSelectedSchoolAsync(this.selectedSchool)

    if (this.selectedSchool.id === notPartneredSchoolId) {
      this.activeStep = 1
      this.selectedSchoolGroup = undefined
      await this.setSelectedSchoolGroupAsync(undefined)
      return
    }

    this.schoolGroups = this.getSchoolGroups(schoolId)

    // if theres only 1 then set it since it isn't shown as an option to choose
    if (this.selectedSchoolGroup && !this.schoolGroups.includes(this.selectedSchoolGroup)) {
      if (this.schoolGroups.length === 1) {
        this.selectedSchoolGroup = this.schoolGroups[0]
        await this.setSelectedSchoolGroupAsync(this.schoolGroups[0])
      } else {
        this.selectedSchoolGroup = undefined
        await this.setSelectedSchoolGroupAsync(undefined)
        this.schoolGroupKey++
        this.activeStep = 1
        return
      }
    } else if (this.selectedSchoolGroup && this.schoolGroups.includes(this.selectedSchoolGroup)) {
      await this.setSelectedSchoolGroupAsync(this.selectedSchoolGroup)
      this.schoolGroupKey++
    }

    if (!this.selectedSchoolGroup) {
      if (this.schoolGroups.length === 1) {
        this.selectedSchoolGroup = this.schoolGroups[0]
        await this.setSelectedSchoolGroupAsync(this.schoolGroups[0])
        this.schoolGroupKey++
        this.activeStep = 1
      } else {
        this.schoolGroupKey++
        this.activeStep = 1
        return
      }
    }

    // increase the step if it hasn't already
    if (this.activeStep < this.getStepNumber(1)) {
      this.activeStep = this.getStepNumber(1)
    }

    this.onValidDates()
  }

  async onSchoolGroupPickedAsync (schoolGroupId: string) {
    this.showNoSchoolGroupWarning = false
    this.selectedSchoolGroup = this.schoolGroups?.filter(sg => sg.id === schoolGroupId)[0]
    await this.setSelectedSchoolGroupAsync(this.selectedSchoolGroup)
    this.onValidDates()
  }

  async cleanupDaysAsync () {
    // it might be the case that location A has all 5 days and location B has only 3 days,
    // we clean up the days in the state so that further references of them are true to what is shown and eventually given
    const availability = this.getDayCheckboxState(true, true)

    if (this.serviceKind === ServiceKind.DayCare) {
      availability.KDV.forEach((item) => {
        if (item.type === DayCheckboxType.Closed) {
          this.userSelectionFormState.daysPerServiceVariety.KDV.filter(i => i.day === item.day)[0].isChecked = false
        }
      })
      await this.setServiceVarietyDaysAsync({ serviceVariety: ServiceVarietyName.KDV, days: this.userSelectionFormState.daysPerServiceVariety.KDV })
    } else {
      availability.NSO.forEach((item) => {
        if (item.type === DayCheckboxType.Closed) {
          this.userSelectionFormState.daysPerServiceVariety.NSO.filter(i => i.day === item.day)[0].isChecked = false
          this.userSelectionFormState.daysPerServiceVariety.VSO.filter(i => i.day === item.day)[0].isChecked = false
        }
      })
      availability.VSO.forEach((item) => {
        if (item.type === DayCheckboxType.Closed) {
          this.userSelectionFormState.daysPerServiceVariety.VSO.filter(i => i.day === item.day)[0].isChecked = false
        }
      })
      await this.setServiceVarietyDaysAsync({ serviceVariety: ServiceVarietyName.NSO, days: this.userSelectionFormState.daysPerServiceVariety.NSO })
      await this.setServiceVarietyDaysAsync({ serviceVariety: ServiceVarietyName.VSO, days: this.userSelectionFormState.daysPerServiceVariety.VSO })
    }
  }

  async onOrderClickedAsync () {
    await this.cleanupDaysAsync()

    if (this.isBso) {
      if (!this.selectedSchool) {
        this.showNoSchoolWarning = true
        this.activeStep = 1
      }
      if (this.selectedSchool?.id === notPartneredSchoolId) {
        this.showNotPartneredSchoolAsWarning = true
        this.activeStep = 1
      }
      if (!this.selectedSchoolGroup && this.schoolGroups && this.schoolGroups.length > 1) {
        this.showNoSchoolGroupWarning = true
        this.activeStep = 1
      }
    }

    if (!this.userSelectionFormState.dateOfBirth || !this.userSelectionFormState.startDateOfDayCare) {
      this.showNoDatesWarning = true
      this.activeStep = this.getStepNumber(1)
    }
    if (!this.hasDaysCheckedForCurrentServiceKind()) {
      this.showNoDaysWarning = true
      this.activeStep = this.getStepNumber(2)
    }

    if (this.showNoSchoolWarning || this.showNoSchoolGroupWarning || this.showNoDatesWarning || this.showNoDaysWarning || this.showNotPartneredSchoolAsWarning) {
      const element = this.$refs[this.activeStep] as HTMLElement
      element.scrollIntoView()
      return
    }

    if (this.proposition) {
      sendFastTrackRegistrationAnalyticsEvent(this.proposition)
    }

    this.$router.push({ name: ROUTES.productAndWaitingListPreference })
  }

  onChangeStartDateToBso () {
    this.userSelectionFormState.startDateOfDayCare = this.childsFourthBirthday
    this.birthAndStartDateKey++
  }

  onBackButtonClicked () {
    if (this.serviceKind && this.service.mdmId) {
      this.$router.redirectToPartouSite(ROUTES.external.getLocationPage(), this.serviceKind as ServiceKind, this.service.mdmId)
    } else {
      window.history.back()
    }
  }

  onFindOtherLocationsClicked () {
    window.location.href = process.env.VUE_APP_PARTOU_HOME_PAGE_PLACEHOLDER + 'kinderopvang/?locator-type=' + (this.serviceKind === ServiceKind.DayCare ? 'Daycare' : 'After+school+care') + '&postal-code=' + this.service.offers[0].postalCode + '&scroll-to-result=1'
  }
}
