
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouTextField from '@/components/PartouComponents/Input/PartouTextField/PartouTextField.vue'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'
import PartouButtonToggle from '@/components/PartouComponents/Input/PartouButtonToggle/PartouButtonToggle.vue'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import IContactDetailsFormState from '@/store/modules/contactDetails/IContactDetailsFormState'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE, MUTATIONS } from '@/store'
import moment from 'moment'
import IContactDetailCareTaker from '@/store/modules/contactDetails/IContactDetailCareTaker'
import Gender from '@/models/enums/Gender'
import IPostalCodeService from '@/services/PostalCodeService/IPostalCodeService'
import PostalCodeServiceError from '@/services/PostalCodeService/PostalCodeServiceErrors'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { eventBus } from '@/EventBus'
import { inputRegexDirective } from '@/utils/directives'
import { regexes } from '@/definitions'
import PartouTextButton from '@/components/PartouComponents/Buttons/PartouTextButton.vue'
import PostalCodeInfo from '@/models/PostalCodeInfo'
import { OnPasteRemoveInvalidCharacters } from '@/utils/stringUtils'

const contactDetailsModule = namespace(NAMESPACES.contactDetails)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouButton, PartouTextField, PartouCheckBox, DateInputField, PartouTextButton, PartouButtonToggle },
  directives: { inputRegexDirective },
  data: () => ({ regexes })
})
export default class OrderContactInfoForm extends Vue {
  @Ref('careTakers.initials.1')
  secondaryCareTakerInitials!: PartouTextField[]

  @Prop({ required: false, default: false })
  disabled!: boolean

  @contactDetailsModule.State(STATE.contactDetails.formState)
  contactDetailsFormState!: IContactDetailsFormState

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @contactDetailsModule.Mutation(MUTATIONS.contactDetails.setFormState)
  setFormState!: (formState: IContactDetailsFormState) => void

  postalCodeService!: IPostalCodeService

  created () : void {
    this.postalCodeService = container.get<IPostalCodeService>(SERVICE_IDENTIFIERS.IPostalCodeService)
  }

  mounted () : void {
    eventBus.$on('secondCareTakerEditClicked', () => { this.activeTabIndex = 1 })
    this.formattedDateOfBirth = moment(this.userSelectionFormState.dateOfBirth).format('DD - MM - YYYY')

    this.hasFutureDateOfBirth = !!this.userSelectionFormState.dateOfBirth && moment(this.userSelectionFormState.dateOfBirth) > moment().endOf('day')
    if (this.hasFutureDateOfBirth && !this.contactDetailsFormState.child.firstName) {
      this.contactDetailsFormState.child.firstName = this.$t('orderContactInfoFormPage.defaultChildName').toString()
    }
  }

  activeTabIndex = 0
  isValid = false
  formattedDateOfBirth = ''
  minimumCareTakerAge = 18 // 18 is the minimum age to be a child's guardian
  maxDate = moment().add(-this.minimumCareTakerAge, 'years').toDate()
  addressLoading = false
  hasFutureDateOfBirth = false
  unknownPostalCodeMessage = ''
  maxChars = 6

  setActiveTabIndex (index: number) : void {
    this.activeTabIndex = index
    Vue.set(this, 'activeTabIndex', index)
  }

  genders = [
    { value: Gender.Female, label: 'orderContactInfoFormPage.form.genders.female' },
    { value: Gender.Male, label: 'orderContactInfoFormPage.form.genders.male' },
    { value: Gender.Other, label: 'orderContactInfoFormPage.form.genders.other' }
  ]

  toggleGenderEditMode () : void {
    this.setCurrentGenderLabel()
  }

  currentGenderLabel = ''
  setCurrentGenderLabel () : void {
    this.currentGenderLabel = this.genders.find(x => x.value === this.contactDetailsFormState?.child?.gender)?.label ?? this.currentGenderLabel
  }

  @Watch('isValid')
  onIsValidUpdated () : void {
    this.$emit('onIsValidUpdated', this.isValid)
  }

  addCareTaker () : void {
    this.contactDetailsFormState.careTakers.push({
      postalCode: this.contactDetailsFormState.careTakers[0].postalCode,
      houseNumber: this.contactDetailsFormState.careTakers[0].houseNumber,
      houseNumberAddition: this.contactDetailsFormState.careTakers[0].houseNumberAddition,
      street: this.contactDetailsFormState.careTakers[0].street,
      locality: this.contactDetailsFormState.careTakers[0].locality
    } as IContactDetailCareTaker)
    this.onFormDataChanged()
    this.activeTabIndex = this.contactDetailsFormState.careTakers.length - 1

    this.$nextTick(() => {
      this.secondaryCareTakerInitials[0].focus()
    })
  }

  removeCareTaker (index: number) : void {
    if (this.contactDetailsFormState.careTakers.length === 1 || this.disabled) return

    this.contactDetailsFormState.careTakers.splice(index, 1)
    this.onFormDataChanged()
    Vue.set(this, 'activeTabIndex', 0)
  }

  getCareTakerName (index: number): string {
    const caretaker = this.contactDetailsFormState.careTakers[index]
    if (caretaker.firstName && caretaker.firstName.trim() !== '' && caretaker.lastName && caretaker.lastName.trim() !== '') {
      return `${caretaker.firstName} ${caretaker.middleName && caretaker.middleName.trim() !== '' ? caretaker.middleName + ' ' : ''}${caretaker.lastName}`
    }

    return this.$t('orderContactInfoFormPage.thisCareTaker').toString()
  }

  onInitialsChanged (index: number) : void {
    // Sets all values to all caps and seperated by a dot
    const original = this.contactDetailsFormState.careTakers[index].initials

    if (original != null) {
      const rawInitials = original.replace(/\./g, '').substring(0, 4)
      if (rawInitials.length > 0) {
        const initials = rawInitials.toUpperCase().split('').join('.') + '.'
        this.contactDetailsFormState.careTakers[index].initials = initials.substring(0, 7)
      }
    }

    this.onFormDataChanged()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPasteRemoveInvalidCharacters (value : any, index : number) : void {
    this.contactDetailsFormState.careTakers[index].postalCode = OnPasteRemoveInvalidCharacters(value, this.contactDetailsFormState.careTakers[index].postalCode.length, this.maxChars)
  }

  onPhoneNumberChanged (index: number) : void {
    // Removes all whitespaces
    const original = this.contactDetailsFormState.careTakers[index].phoneNumber
    this.contactDetailsFormState.careTakers[index].phoneNumber = original?.replace(/ /g, '')
    this.onFormDataChanged()
  }

  async onAddressChangedAsync (careTakerIndex: number) : Promise<void> {
    this.addressLoading = true
    const postalCode = this.contactDetailsFormState.careTakers[careTakerIndex].postalCode
    const houseNumber = Number(this.contactDetailsFormState.careTakers[careTakerIndex].houseNumber)
    const houseNumberAddition = this.contactDetailsFormState.careTakers[careTakerIndex].houseNumberAddition

    let addressInfo : PostalCodeInfo | undefined
    this.unknownPostalCodeMessage = ''
    if (postalCode != null && postalCode.length > 0 && this.isPostalCodeRule(postalCode) === true && !isNaN(houseNumber) && houseNumber > 0) {
      const response = await this.postalCodeService.getPostalCodeInfo({ postalCode, houseNumber, houseNumberAddition })
      const postalCodeForms = this.$refs['postal-code-form'] as HTMLFormElement[]
      postalCodeForms[0]?.validate()

      if (response?.errorCode === PostalCodeServiceError.notFound) {
        this.unknownPostalCodeMessage = this.$t('form.unknownPostalCodeHouseNumberCombination').toString()
      } else if (response?.result) {
        this.unknownPostalCodeMessage = ''
        addressInfo = response.result
      }
    }

    if (addressInfo) {
      this.contactDetailsFormState.careTakers[careTakerIndex].street = addressInfo.street as string
      this.contactDetailsFormState.careTakers[careTakerIndex].locality = addressInfo.city as string
    }

    this.addressLoading = false
    this.onFormDataChanged()
  }

  isBsnRequired () : boolean {
    const requiredBeforeDate = moment().add(-4, 'weeks')
    const dateOfBirth = moment(this.userSelectionFormState.dateOfBirth)
    return dateOfBirth <= requiredBeforeDate
  }

  onGenderChanged (gender: string) : void {
    this.contactDetailsFormState.child.gender = gender
    this.onFormDataChanged()
  }

  onFormDataChanged () : void {
    this.setFormState(this.contactDetailsFormState)
  }

  isEmailRule (value: string) : boolean | string {
    const emailRegex = regexes.validation.emailRegex
    return emailRegex.test(value ?? '') || this.$t('form.invalidEmail').toString()
  }

  isPhoneNumberRule (value: string) : boolean | string {
    const phoneNumberRegex = regexes.validation.phoneNumberRegex
    return phoneNumberRegex.test(value) || this.$t('form.invalidPhoneNumber').toString()
  }

  isInitialsRule (value: string) : boolean | string {
    const initialsRegex = regexes.validation.initialRegex
    return initialsRegex.test(value ?? '') || this.$t('form.invalidInitials').toString()
  }

  isMiddleNameRule (value: string) : boolean | string {
    const nameRegex = regexes.validation.nameRegex
    return !value || value.length === 0 || nameRegex.test(value) || this.$t('form.invalidName').toString()
  }

  isNameRule (value: string) : boolean | string {
    const nameRegex = regexes.validation.nameRegex
    return nameRegex.test(value ?? '') || this.$t('form.invalidName').toString()
  }

  isMinimumCareTakerAgeRule (value: string) : boolean | string {
    const inputDate = moment(value, 'dd-MM-yyyy').toDate()
    return inputDate <= moment().add(-this.minimumCareTakerAge, 'years').toDate() || this.$t('form.minimalAgeRequirement', { age: this.minimumCareTakerAge }).toString()
  }

  isBsnRule (value: string) : boolean | string {
    if (!value) {
      return true
    }

    const numbersRegex = regexes.validation.bsnRegex
    return (numbersRegex.test(value ?? '') && this.elfproef(value) && value !== '000000000') || this.$t('form.invalidBsn').toString()
  }

  isChildBsnUniqueRule (value:string): boolean | string {
    const sameAs = []

    if (this.contactDetailsFormState.careTakers[0].bsn === value) {
      sameAs.push(this.$t('orderContactInfoFormPage.primaryCareTaker'))
    }
    if (this.contactDetailsFormState.careTakers[1]?.bsn === value) {
      sameAs.push(this.$t('orderContactInfoFormPage.secondaryCareTaker'))
    }

    switch (sameAs.length) {
    case 0:
      return true
    case 1:
      return this.$t('form.sameBsn', sameAs).toString()
    default:
      return this.$t('form.sameBsnMultiple', sameAs).toString()
    }
  }

  isCareTakerBsnUniqueRule (index: number): (value: string) => boolean | string {
    return index === 0 ? this.isPrimaryCareTakerBsnUniqueRule : this.isSecondaryCareTakerBsnUniqueRule
  }

  isPrimaryCareTakerBsnUniqueRule (value: string): boolean | string {
    const sameAs = []
    if (this.contactDetailsFormState.child.bsn === value) {
      sameAs.push(this.$t('orderContactInfoFormPage.child'))
    }
    if (this.contactDetailsFormState.careTakers[1]?.bsn === value) {
      sameAs.push(this.$t('orderContactInfoFormPage.secondaryCareTaker'))
    }

    switch (sameAs.length) {
    case 0:
      return true
    case 1:
      return this.$t('form.sameBsn', sameAs).toString()
    default:
      return this.$t('form.sameBsnMultiple', sameAs).toString()
    }
  }

  isSecondaryCareTakerBsnUniqueRule (value: string): boolean | string {
    const sameAs = []
    if (this.contactDetailsFormState.child.bsn === value) {
      sameAs.push(this.$t('orderContactInfoFormPage.child'))
    }
    if (this.contactDetailsFormState.careTakers[0].bsn === value) {
      sameAs.push(this.$t('orderContactInfoFormPage.primaryCareTaker'))
    }

    switch (sameAs.length) {
    case 0:
      return true
    case 1:
      return this.$t('form.sameBsn', sameAs).toString()
    default:
      return this.$t('form.sameBsnMultiple', sameAs).toString()
    }
  }

  onBsnChanged () : void {
    this.onFormDataChanged()

    const childBsnForm = this.$refs['child-bsn-form'] as HTMLFormElement
    const careTakerBsnForms = this.$refs['care-taker-bsn-forms'] as HTMLFormElement[]
    if (childBsnForm && this.contactDetailsFormState.child.bsn) {
      childBsnForm.validate()
    }
    if (careTakerBsnForms) {
      for (let i = 0; i < careTakerBsnForms.length; ++i) {
        if (this.contactDetailsFormState.careTakers[i].bsn) {
          careTakerBsnForms[i].validate()
        }
      }
    }
  }

  isPostalCodeRule (postalCode: string): boolean | string {
    return (this.postalCodeService.isPostalCodeFormatValid(postalCode ?? '') && !this.unknownPostalCodeMessage) || this.$t('form.invalidPostalCode').toString()
  }

  isHouseNumberRule (value: string) : boolean | string {
    const numbersRegex = /^[1-9]\d*$/
    return numbersRegex.test(value ?? '') || this.$t('form.invalidHouseNumber').toString()
  }

  isHouseNumberAdditionRule (value: string) : boolean | string {
    const additionRegex = /^[A-Za-z0-9]{0,2}$/
    return additionRegex.test(value ?? '') || this.$t('form.invalidHouseNumberAddition').toString()
  }

  elfproef (value: string) : boolean {
    let sum = 0
    for (let i = 0; i < 8; i++) {
      sum += (9 - i) * parseInt(value.charAt(i))
    }
    sum -= parseInt(value.charAt(8))

    return ((sum % 11) === 0)
  }
}
