
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Proposition, ServiceVarietyName } from '@/models'
import moment from 'moment'
import MultiStateDaySelector from '@/components/MultiStateDaySelector/MultiStateDaySelector.vue'
import MultiStateDaySelectorWithStartDate from '@/components/MultiStateDaySelectorWithStartDate/MultiStateDaySelectorWithStartDate.vue'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { namespace } from 'vuex-class'
import { ACTIONS, GETTERS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import AcceptWaitingListLegend from '@/components/MultiStateDaySelector/AcceptWaitingListLegend.vue'
import MultiStateDaySelectorLegend from '@/components/MultiStateDaySelector/MultiStateDaySelectorLegend.vue'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DaySelectionState from '@/models/types/DaySelectionState'
import FakeLink from '@/components/Generic/FakeLink.vue'
import { ROUTES } from '@/router/routes'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const propositionModule = namespace(NAMESPACES.proposition)

@Component({
  components: { MultiStateDaySelectorWithStartDate, MultiStateDaySelector, AcceptWaitingListLegend, PartouNotify, MultiStateDaySelectorLegend, FakeLink }
})
export default class SelectionContent extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @propositionModule.Action(ACTIONS.proposition.getPropositionsAsync)
  getPropositionsAsync!: () => Promise<void>

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setFormState)
  setFormState!: (formState: IUserSelectionFormState) => void

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceVarietyDaysAsync)
  setSelectedServiceVarietyDaysAsync!: (daysPerServiceVariety: Record<ServiceVarietyName, DaySelectionState[]>, proposition?: Proposition) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxStateWaitingListProposition)
  getDayCheckboxStateWaitingListProposition!: () => Record<ServiceVarietyName, DayCheckboxState[]>

  @userSelectionModule.Action(ACTIONS.userSelection.setServiceVarietyDaysAsync)
  setServiceVarietyDaysAsync!: (input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) => Promise<void>

  @Prop({ required: true })
  proposition!: Proposition

  @Prop({ required: false, default: false })
  allDaysOffered!: boolean

  showLegend = false
  isInitialDayChanged = true
  isLoadingPropositions = false

  get startDateOfDayCare () : string {
    return moment(this.userSelectionFormState.startDateOfDayCare).locale('nl').format('D MMMM YYYY')
  }

  get isPreorder () : boolean {
    return !!(this.proposition && this.proposition.isPreorder)
  }

  mounted (): void {
    const checkboxStates = this.getCheckboxState()
    this.setServiceVarietyDaysAsync({ serviceVariety: ServiceVarietyName.KDV, days: checkboxStates.KDV })
    this.setServiceVarietyDaysAsync({ serviceVariety: ServiceVarietyName.VSO, days: checkboxStates.VSO })
    this.setServiceVarietyDaysAsync({ serviceVariety: ServiceVarietyName.NSO, days: checkboxStates.NSO })
  }

  @Emit('onDaySelectionChanged')
  emitOnDaySelectionChanged () : void {
    // emit on dayselection changed
  }

  getCheckboxState () : Record<ServiceVarietyName, DayCheckboxState[]> {
    if (this.userSelectionFormState.isAcceptingWaitinglist) {
      return this.getDayCheckboxStateWaitingListProposition()
    } else {
      return this.getDayCheckboxState(true, true)
    }
  }

  async onDaySelectorChangedAsync () : Promise<void> {
    this.isLoadingPropositions = true

    if (!this.isPreorder) {
      await this.getPropositionsAsync()
    }
    this.isLoadingPropositions = false
    this.emitOnDaySelectionChanged()
  }

  onPropositionNameClicked () : void {
    this.$router.push({ name: ROUTES.locationDetail, params: { serviceSlug: this.proposition.slug! } }) // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }
}
