import { ServiceVarietyName } from '@/models'

export class ProductViewData {
  constructor (id: string, startTime: string, endTime: string, pricePerHour: number, serviceVariety: string, textualAddition?: string) {
    this.id = id
    this.startTime = startTime
    this.endTime = endTime
    this.pricePerHour = pricePerHour
    this.serviceVariety = serviceVariety
    this.textualAddition = textualAddition

    if (serviceVariety === ServiceVarietyName.KDV) {
      this.label = startTime.slice(0, 5) + ' - ' + endTime.slice(0, 5)
    } else if (serviceVariety === ServiceVarietyName.NSO) {
      this.label = endTime.slice(0, 5)
    } else if (serviceVariety === ServiceVarietyName.VSO) {
      this.label = startTime.slice(0, 5)
    }
  }

    id!: string
    startTime!: string
    endTime!: string
    pricePerHour!: number
    serviceVariety!: string
    textualAddition?: string
    label!: string
}
