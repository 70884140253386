import { Module } from 'vuex'
import IState from '@/store/IState'
import IUserSelectionState, { STATE } from './IUserSelectionState'
import { mutations, MUTATIONS } from './userSelectionMutations'
import { actions, ACTIONS } from './userSelectionActions'
import { getters, GETTERS } from './userSelectionGetters'

import { DayOfWeek, ServiceKind } from '@/models'

export function state (): IUserSelectionState {
  return {
    formState: {
      postalCode: { postcode: undefined, isLoading: false },
      dateOfBirth: undefined,
      startDateOfDayCare: undefined,
      selectedSchool: undefined,
      selectedService: undefined,
      selectedServiceKind: ServiceKind.DayCare,
      selectedServiceVarieties: [],
      daysPerServiceVariety: {
        KDV: [
          { day: DayOfWeek.Monday, isChecked: false },
          { day: DayOfWeek.Tuesday, isChecked: false },
          { day: DayOfWeek.Wednesday, isChecked: false },
          { day: DayOfWeek.Thursday, isChecked: false },
          { day: DayOfWeek.Friday, isChecked: false }
        ],
        NSO: [
          { day: DayOfWeek.Monday, isChecked: false },
          { day: DayOfWeek.Tuesday, isChecked: false },
          { day: DayOfWeek.Wednesday, isChecked: false },
          { day: DayOfWeek.Thursday, isChecked: false },
          { day: DayOfWeek.Friday, isChecked: false }
        ],
        VSO: [
          { day: DayOfWeek.Monday, isChecked: false },
          { day: DayOfWeek.Tuesday, isChecked: false },
          { day: DayOfWeek.Wednesday, isChecked: false },
          { day: DayOfWeek.Thursday, isChecked: false },
          { day: DayOfWeek.Friday, isChecked: false }
        ]
      },
      isUsingChildbenefitCalculator: false,
      isAcceptingWaitinglist: false,
      isExpansionWaitinglist: false,
      waitingListPreference: false
    },
    formIsValid: false,
    currentStep: 0,
    isFastTrack: false
  }
}

export function module (): Module<IUserSelectionState, IState> {
  return {
    namespaced: true,
    state: state(),
    mutations: mutations(),
    actions: actions(),
    getters: getters()
  }
}
export const NAMESPACE = 'userSelection'
export { ACTIONS }
export { MUTATIONS }
export { GETTERS }
export { STATE }
