
import { Component, Vue } from 'vue-property-decorator'
import { ACTIONS, GETTERS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import PartouRadioOn from '@/components/PartouComponents/Icons/PartouRadioOn.vue'
import PartouRadioOff from '@/components/PartouComponents/Icons/PartouRadioOff.vue'
import { ServiceVarietyName } from '@/models'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import moment from 'moment'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouNotify, PartouRadioOn, PartouRadioOff }
})
export default class WaitingListPreferenceSelect extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Action(ACTIONS.userSelection.setWaitingListPreference)
  setWaitingListPreference!: (waitingListPreference : boolean) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  stayOnWaitingList = false

  showDirectTooltip = false
  showWaitingListTooltip = false

  directDays: string[] = []
  waitingListDays: string[] = []
  allDays: string[] = []

  mounted () {
    this.stayOnWaitingList = this.userSelectionFormState.waitingListPreference ?? false
    const dayCheckBoxState = this.getDayCheckboxState(true, false)[ServiceVarietyName.KDV]
    this.allDays = dayCheckBoxState.filter(day => day.isChecked).map(day => day.day)
    this.directDays = dayCheckBoxState.filter(day => day.isChecked && day.type === DayCheckboxType.Check).map(day => day.day)
    this.waitingListDays = dayCheckBoxState.filter(day => day.isChecked && day.type === DayCheckboxType.Wait).map(day => day.day)
  }

  categoryClicked (stayOnWaitingList: boolean) {
    this.stayOnWaitingList = stayOnWaitingList
    this.setWaitingListPreference(stayOnWaitingList)
  }

  formatDays (days: string[]): string {
    if (days.length === 0) return ''
    if (days.length === 1) return this.getDayLabel(days[0]).toLowerCase()
    if (days.length === 2) return `${this.getDayLabel(days[0]).toLowerCase()} ${this.$t('orderDirectPage.waitingListPreferenceSelection.and')} ${this.getDayLabel(days[1]).toLowerCase()}`
    return `${days.slice(0, -1).map(day => this.getDayLabel(day).toLowerCase()).join(', ')} ${this.$t('orderDirectPage.waitingListPreferenceSelection.and')} ${this.getDayLabel(days[days.length - 1]).toLowerCase()}`
  }

  getDayLabel (day: string): string {
    switch (day) {
    case 'Monday':
      return this.$t('days.monday').toString()
    case 'Tuesday':
      return this.$t('days.tuesday').toString()
    case 'Wednesday':
      return this.$t('days.wednesday').toString()
    case 'Thursday':
      return this.$t('days.thursday').toString()
    case 'Friday':
      return this.$t('days.friday').toString()
    case 'Saturday':
      return this.$t('days.saturday').toString()
    case 'Sunday':
      return this.$t('days.sunday').toString()
    }
    return ''
  }

  getDirectDaysWithStartDates (): [string, string][] {
    const startDate = moment(this.userSelectionFormState.startDateOfDayCare)

    const result: [string, string][] = []

    this.directDays.forEach(day => {
      const dayIndex = moment().day(day).day()
      const nextDayDate = moment(startDate).day(dayIndex)
      if (nextDayDate.isBefore(startDate, 'day')) {
        nextDayDate.add(1, 'week')
      }

      result.push([this.getDayLabel(day), this.$t('orderDirectPage.waitingListPreferenceSelection.from') + nextDayDate.day(dayIndex).locale('nl').format('D MMMM YYYY')])
    })

    return result
  }
}
