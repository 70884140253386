
import { Vue, Component, Prop } from 'vue-property-decorator'
import ChildBenefitCalculatorInputForm from '@/components/ChildBenefitCalculator/ChildBenefitCalculator.vue'
import ChildBenefitSummaryTable from '@/components/ChildBenefitCalculator/ChildBenefitSummaryTable.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import { ACTIONS, GETTERS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import PropositionSubscription from '@/models/PropositionSubscription'
import IPropositionService from '@/services/PropositionService/IPropositionService'
import { ICalculatorGetters } from '@/store/modules/childBenefitCalculator'
import ICalculatorConstants from '@/store/modules/childBenefitCalculator/ICalculatorConstants'
import ICalculatorFormState from '@/store/modules/childBenefitCalculator/ICalculatorFormState'
import { euroFilter } from '@/utils/filters'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { Offer, ServiceKind, ServiceVarietyName } from '@/models'

const childBenefitCalculatorModule = namespace(NAMESPACES.childBenefitCalculator)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { ChildBenefitCalculatorInputForm, ChildBenefitSummaryTable, PartouButton, PartouNotify }
})
export default class ChildBenefitCalculatorResult extends Vue {
  @Prop({ required: true })
  propositionSubscription!: PropositionSubscription

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.constants)
  calculatorConstants! : ICalculatorConstants

  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.formState)
  calculatorFormState! : ICalculatorFormState

  @childBenefitCalculatorModule.Action(ACTIONS.childBenefitCalculator.fetchConstantsOnceAsync)
  fetchConstantsOnceAsync! : () => void

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getBenefitsPerHour)
  getBenefitsPerHour! : ICalculatorGetters['getBenefitsPerHour']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getBenefitsPerMonth)
  getBenefitsPerMonth! : ICalculatorGetters['getBenefitsPerMonth']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getOwnContributionPerMonthForAvailableDays)
  getOwnContributionPerMonthForAvailableDays! : ICalculatorGetters['getOwnContributionPerMonthForAvailableDays']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getOwnContributionPerMonthForTotalDays)
  getOwnContributionPerMonthForTotalDays! : ICalculatorGetters['getOwnContributionPerMonthForTotalDays']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getOwnContributionPerHour)
  getOwnContributionPerHour! : ICalculatorGetters['getOwnContributionPerHour']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getRemainingDeclarableCareHoursPerMonth)
  getRemainingDeclarableCareHoursPerMonth! : ICalculatorGetters['getRemainingDeclarableCareHoursPerMonth']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getCareHoursPerMonth)
  getCareHoursPerMonth! : ICalculatorGetters['getCareHoursPerMonth']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getCompensatedPercentage)
  getCompensatedPercentage! : ICalculatorGetters['getCompensatedPercentage']

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getDeclarableHourlyRate)
  getDeclarableHourlyRate! : ICalculatorGetters['getDeclarableHourlyRate']

  propositionService! : IPropositionService

  selectedOffer? : Offer
  withWaitingListHours = true
  isBenefitAmountInfoVisible = false
  currentYear = new Date().getFullYear()
  index = 0

  mounted () {
    this.$emit('calculationFinished')
  }

  get serviceVarietyNames (): ServiceVarietyName[] { return this.userSelectionFormState.selectedServiceKind === ServiceKind.DayCare ? [ServiceVarietyName.KDV] : [ServiceVarietyName.VSO, ServiceVarietyName.NSO] }

  get formattedBenefitsPerHour (): string {
    return euroFilter(this.benefitsPerHour)
  }

  get compensatedPercentage (): number {
    return this.getCompensatedPercentage && this.getCompensatedPercentage()
  }

  get costsPerHour (): number { return this.propositionSubscription?.pricePerHour ?? 0 }

  get costsPerMonth (): number {
    return this.propositionSubscription?.priceOfFirstFullMonthForTotalDays ?? 0
  }

  get remainingDeclarableCareHoursPerMonth (): number {
    return this.propositionSubscription
      ? this.getRemainingDeclarableCareHoursPerMonth({ serviceVarietyNames: this.serviceVarietyNames, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get careHoursPerMonth (): number {
    return this.propositionSubscription
      ? this.getCareHoursPerMonth({ propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get benefitsPerMonth (): number {
    return this.propositionSubscription
      ? this.getBenefitsPerMonth({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get benefitsPerHour (): number {
    return this.propositionSubscription
      ? this.getBenefitsPerHour({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get ownContributionPerMonthForAvailableDays (): number {
    return this.propositionSubscription
      ? this.getOwnContributionPerMonthForAvailableDays({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: false })
      : 0
  }

  get ownContributionPerMonthForTotalDays (): number {
    return this.propositionSubscription
      ? this.getOwnContributionPerMonthForTotalDays({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get ownContributionPerHour (): number {
    return this.propositionSubscription
      ? this.getOwnContributionPerHour({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get formattedCumulativeIncomePerYear (): string {
    return euroFilter(this.calculatorFormState?.cumulativeIncomePerYear, true)
  }

  get formattedDeclarableHourlyRate (): string {
    return euroFilter(this.declarableHourlyRate)
  }

  get declarableHourlyRate (): number {
    return this.getDeclarableHourlyRate && this.getDeclarableHourlyRate({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour })
  }

  getIsExceedingCareHourLimit () : boolean {
    return this.remainingDeclarableCareHoursPerMonth < 0
  }

  getPopupWidth () : number {
    const width = window.innerWidth - 96
    return width > 520 ? 520 : width
  }

  onBenefitAmountInfoClicked (): void {
    this.isBenefitAmountInfoVisible = true
  }
}
