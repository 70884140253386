import { GetSchoolAndReferencesByIdDocument, GetSchoolAndReferencesByIdQueryVariables, GetSchoolGroupsBySchoolIdQuery, GetSchoolGroupsBySchoolIdDocument, GetSchoolGroupsBySchoolIdQueryVariables, GetSchoolLocalityOptionsQuery, GetSchoolLocalityOptionsDocument, GetSchoolLocalityOptionsQueryVariables, QueryRoot, School, SchoolGroup, SearchSchoolByLocalityAndNameQuery, SearchSchoolByLocalityAndNameDocument, SearchSchoolByLocalityAndNameQueryVariables, GetSchoolLocalityOptionsWhereIsAvailableInCalculatorQuery, GetSchoolByLocalityAndNameWhereIsAvailableInCalculatorQuery, GetSchoolByLocalityAndNameWhereIsAvailableInCalculatorQueryVariables, GetSchoolByLocalityAndNameWhereIsAvailableInCalculatorDocument, GetSchoolLocalityOptionsWhereIsAvailableInCalculatorDocument, GetSchoolByServiceIdQueryVariables, GetSchoolByServiceIdQuery, GetSchoolByServiceIdDocument } from '@/models/generated/graphql'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import ISchoolServiceService, { getSchoolByLocalityAndNamePayload, getSchoolGroupsBySchoolIdPayload } from './ISchoolServiceService'

@injectable()
export default class SchoolServiceService implements ISchoolServiceService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getSchoolLocalityOptions (isUsingChildbenefitCalculator : boolean, serviceId : string) : Promise<string[]> {
    if (isUsingChildbenefitCalculator) {
      const result = await this.apollo.query<GetSchoolLocalityOptionsWhereIsAvailableInCalculatorQuery>({
        query: GetSchoolLocalityOptionsWhereIsAvailableInCalculatorDocument
      })
      return result.data.school.flatMap(x => x.locality) as string[]
    }

    const byServiceId = serviceId !== ''
    const variables: GetSchoolLocalityOptionsQueryVariables = { serviceId, byServiceId }

    const result = await this.apollo.query<GetSchoolLocalityOptionsQuery>({
      query: GetSchoolLocalityOptionsDocument,
      variables
    })

    if (result.data.localitiesByServiceId) {
      return result.data.localitiesByServiceId.flatMap(x => x.locality)
    }
    if (result.data.localities) {
      return result.data.localities.flatMap(x => x.locality)
    }
    return []
  }

  async getSchoolByLocalityAndName (input: getSchoolByLocalityAndNamePayload, isUsingChildbenefitCalculator : boolean, serviceId : string): Promise<School[]> {
    const locationInput = input.locationInput
    const nameInput = `%${input.nameInput}%`

    if (isUsingChildbenefitCalculator) {
      const variables: GetSchoolByLocalityAndNameWhereIsAvailableInCalculatorQueryVariables = { locationInput, nameInput }
      const result = await this.apollo.query<GetSchoolByLocalityAndNameWhereIsAvailableInCalculatorQuery>({
        query: GetSchoolByLocalityAndNameWhereIsAvailableInCalculatorDocument,
        variables
      })
      return result.data.school as School[]
    }

    const byServiceId = serviceId !== ''
    const variables: SearchSchoolByLocalityAndNameQueryVariables = { locationInput, nameInput, serviceId, byServiceId }
    const result = await this.apollo.query<SearchSchoolByLocalityAndNameQuery>({
      query: SearchSchoolByLocalityAndNameDocument,
      variables
    })

    if (result.data.schoolsByServiceId) {
      return result.data.schoolsByServiceId as School[]
    }
    if (result.data.schools) {
      return result.data.schools as School[]
    }
    return []
  }

  async getSchoolAndReferencesById (id : string): Promise<School> {
    const variables: GetSchoolAndReferencesByIdQueryVariables = { id }
    const result = await this.apollo.query<QueryRoot>({
      query: GetSchoolAndReferencesByIdDocument,
      variables
    })
    return result.data.school[0]
  }

  async getSchoolByLocality (localityName: string): Promise<School[]> {
    const variables:SearchSchoolByLocalityAndNameQueryVariables = { locationInput: localityName, nameInput: '%%', serviceId: '', byServiceId: false }
    const result = await this.apollo.query<SearchSchoolByLocalityAndNameQuery>({
      query: SearchSchoolByLocalityAndNameDocument,
      variables
    })
    return result.data.schools as School[]
  }

  async getSchoolByServiceId (serviceId: string): Promise<School[]> {
    const variables: GetSchoolByServiceIdQueryVariables = { serviceId }
    const result = await this.apollo.query<GetSchoolByServiceIdQuery>({
      query: GetSchoolByServiceIdDocument,
      variables
    })
    return result.data.service[0].schoolServices.flatMap(ss => ss.school) as School[]
  }

  async getSchoolGroupsBySchoolIdAsync (input: getSchoolGroupsBySchoolIdPayload): Promise<SchoolGroup[]> {
    const variables: GetSchoolGroupsBySchoolIdQueryVariables = { ...input }
    const result = await this.apollo.query<GetSchoolGroupsBySchoolIdQuery>({
      query: GetSchoolGroupsBySchoolIdDocument,
      variables
    })

    return result.data.school_group
  }
}
