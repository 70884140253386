
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import Page from '@/pages/Page'
import IShareService from '@/services/ShareService/IShareService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { namespace } from 'vuex-class'
import { MUTATIONS, NAMESPACES, STATE } from '@/store'
import IState from '@/store/IState'
import IUserSelectionState from '@/store/modules/userSelection/IUserSelectionState'
import IContactDetailsState from '@/store/modules/contactDetails/IContactDetailsState'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import i18n from '@/plugins/i18n'
import { decryptObjectAes } from '@/utils/aesCryptoUtils'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const contactDetailsModule = namespace(NAMESPACES.contactDetails)
const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: {
    PartouButton,
    PartouSocials,
    PartouLoader
  },
  metaInfo () {
    return {
      title: (this as SharedPage).pageTitle,
      meta: [
        { property: 'title', content: (this as SharedPage).pageTitle },
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class SharedPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.sharedPage').toString()

  shareService!: IShareService

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setState)
  setUserSelectionState! : (state: IUserSelectionState) => void

  @contactDetailsModule.Mutation(MUTATIONS.contactDetails.setState)
  setContactDetailsState! : (state: IContactDetailsState) => void

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any

  @Prop({ required: true })
  hash!: string

  @Prop({ required: true })
  encryptionKey!: string

  @Prop({ required: false, default: 2000 })
  minimumLoadingTime!: number

  isLoading!: boolean
  homePage: string = process.env.VUE_APP_PARTOU_HOME_PAGE_PLACEHOLDER! /* Must be set */// eslint-disable-line @typescript-eslint/no-non-null-assertion

  beforeCreate () : void {
    this.shareService = container.get<IShareService>(SERVICE_IDENTIFIERS.IShareService)
  }

  created () : void {
    this.isLoading = true
    this.getSharedLinkAsync()
  }

  async getSharedLinkAsync () : Promise<void> {
    // let the loader show for minimal time, regardless the time for fetching and decrypting
    const waitForLoader = new Promise(resolve => setTimeout(resolve, this.minimumLoadingTime))

    if (this.hash && this.encryptionKey) {
      const { encryptedState, sharedFromUrl } = await this.shareService.getEncryptedStateAsync({ hash: this.hash })

      if (encryptedState && sharedFromUrl) {
        let state: Partial<IState> = {}
        try {
          state = decryptObjectAes(encryptedState, this.encryptionKey, this.hash)
        } catch (e) {
          console.warn('Could not decrypt with specified key', e)
        }

        if (state.userSelection && state.contactDetails) {
          this.setUserSelectionState(state.userSelection)
          this.setContactDetailsState(state.contactDetails)

          await waitForLoader

          this.$router.replace(sharedFromUrl)
          return
        }
      }
    }

    await waitForLoader
    this.isLoading = false
    this.$forceUpdate()
  }

  onRestartClick () : void {
    window.location.href = this.homePage
  }
}
