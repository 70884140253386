
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import { formatDate } from '@/utils/dateUtils'
import ProductCategory from '@/models/enums/ProductCategory'
import { GetWaitingListStatusOutput, ServiceKind } from '@/models'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const propositionModule = namespace(NAMESPACES.proposition)
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

@Component({
  components: { PartouCard }
})
export default class LocationPackageCard extends Vue {
  @Prop({ required: true })
  locationName! : string

  @Prop({ required: true })
  isExpansion! : boolean

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @propositionModule.State(STATE.proposition.waitinglistPropositionStatus)
  waitinglistPropositionStatus?: GetWaitingListStatusOutput

  selectedDays = ''
  availableDays = ''

  get schoolName (): string | undefined {
    return this.userSelectionFormState.selectedSchool?.name
  }

  get formattedStartDate (): string {
    return formatDate(this.userSelectionFormState.startDateOfDayCare)
  }

  get selectedProductCategory (): string {
    const productCategory = this.userSelectionFormState.selectedProductCategory || this.waitinglistPropositionStatus?.subscriptionCode
    if (productCategory === ProductCategory.AllWeeks) {
      return this.$t('orderContactInfoFormPage.form.locationAndPackage.allWeeks').toString()
    } else if (productCategory === ProductCategory.SchoolWeeks) {
      return this.$t('orderContactInfoFormPage.form.locationAndPackage.schoolWeeks').toString()
    }

    return ''
  }

  get isKDV (): boolean {
    return this.userSelectionFormState.selectedService?.kind === ServiceKind.DayCare
  }

  get yourChoice (): string {
    return this.userSelectionFormState.waitingListPreference ? this.$t('orderDirectPage.waitingListPreferenceSelection.waitingList.name').toString() : this.$t('orderDirectPage.waitingListPreferenceSelection.directPlacement.name').toString()
  }

  mounted (): void {
    const selectedDays: string[] = []
    const availableDays: string[] = []

    days.forEach((day) => {
      if (this.userSelectionFormState.daysPerServiceVariety.KDV.some(d => d.day === day && d.isChecked) ||
        this.userSelectionFormState.daysPerServiceVariety.NSO.some(d => d.day === day && d.isChecked) ||
        this.userSelectionFormState.daysPerServiceVariety.VSO.some(d => d.day === day && d.isChecked)) {
        selectedDays.push(this.$t(`daysShort.${day.toLowerCase()}`).toString())
      }

      if (this.userSelectionFormState.daysPerServiceVariety.KDV.some(d => d.day === day && d.isChecked && d.type === DayCheckboxType.Check) ||
        this.userSelectionFormState.daysPerServiceVariety.NSO.some(d => d.day === day && d.isChecked && d.type === DayCheckboxType.Check) ||
        this.userSelectionFormState.daysPerServiceVariety.VSO.some(d => d.day === day && d.isChecked && d.type === DayCheckboxType.Check)) {
        availableDays.push(this.$t(`daysShort.${day.toLowerCase()}`).toString())
      }
    })

    this.selectedDays = selectedDays.join(', ')
    this.availableDays = availableDays.join(', ')
  }
}
