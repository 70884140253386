
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component
export default class FeatureDisclaimer extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  get showFirstDisclaimer () {
    return this.userSelectionFormState.daysPerServiceVariety.KDV.some(day => day.isChecked === true && day.type === DayCheckboxType.Check)
  }
}
