
import { Component, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import { NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'

const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: { PartouButton, PartouSocials },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class ServiceNotBookable extends Vue {
  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any

  onBackButtonClicked () {
    window.history.back()
  }
}
