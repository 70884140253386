
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import PartouFloatingCircleButton from '../Buttons/PartouFloatingCircleButton.vue'

@Component({
  components: { PartouFloatingCircleButton }
})
export default class PartouBottomSheet extends Vue {
  @Ref('sheet') sheet!: Vue
  @Ref('innerContent') innerContent!: HTMLElement

  @Prop({})
  value!: boolean

  @Prop({ required: false })
  maxWidth?: string

  initialHeight = 0 // initialHeight is set when sheet is activated in onSheetValueChanged
  closingThreshold = 120
  sheetHeight = 0
  lastClientY: number | null = null
  overlayElement?: EventTarget

  @Watch('sheetValue')
  onSheetValueChanged () : void {
    if (this.value) {
      this.lastClientY = null
      setTimeout((): void => {
        this.initialHeight = (this.$refs.innerContent as HTMLElement)?.scrollHeight
        this.closingThreshold = this.initialHeight * 0.5
        this.sheetHeight = this.initialHeight

        // Add eventListener to the overlay element to close the BottomSheet when clicked outside of the content
        const overlayElements = document.getElementsByClassName('v-overlay v-overlay--active')
        if (overlayElements.length) {
          overlayElements[0].addEventListener('click', () => {
            this.closeSheet()
          })
        }
      }, 0)
      document.documentElement.classList.add('no-scroll')
    } else {
      document.documentElement.classList.remove('no-scroll')
    }
  }

  closeSheet (): void {
    this.sheetValue = false
  }

  get sheetValue (): boolean {
    return this.value
  }

  set sheetValue (value : boolean) {
    this.$emit('input', value)
  }

  dragStart (e: MouseEvent | TouchEvent) : void {
    e.preventDefault()

    // Desktop
    window.addEventListener('mousemove', this.doDrag, { passive: false })
    window.addEventListener('mouseup', this.dragEnd, { passive: false })

    // Mobile
    window.addEventListener('touchmove', this.doDrag, { passive: false })
    window.addEventListener('touchend', this.dragEnd, { passive: false })
  }

  dragEnd (e: MouseEvent | TouchEvent) : void {
    e.preventDefault()

    if (this.sheetHeight > this.closingThreshold) {
      this.sheet.$el.classList.add('sheet-transition')

      this.sheetHeight = this.initialHeight
      this.lastClientY = null

      setTimeout(() => {
        this.sheet.$el.classList.remove('sheet-transition')
      }, 100)
    }

    // Desktop
    window.removeEventListener('mousemove', this.doDrag)
    window.removeEventListener('mouseup', this.dragEnd)

    // Mobile
    window.removeEventListener('touchmove', this.doDrag)
    window.removeEventListener('touchend', this.dragEnd)
  }

  doDrag (e: MouseEvent | TouchEvent) : void {
    e.preventDefault()

    let clientY = 0
    if (e instanceof MouseEvent) {
      clientY = e.clientY
    } else if (e instanceof TouchEvent) {
      clientY = e.touches[0].clientY
    }

    const movementY = this.lastClientY === null ? 0 : clientY - this.lastClientY
    const newSheetHeight = (this.sheetHeight ?? this.initialHeight) - movementY
    // Don't allow resizing larger than the original
    if (newSheetHeight <= this.initialHeight) {
      this.sheetHeight = newSheetHeight
      this.lastClientY = clientY

      // Close sheet if it's below threshold
      if (this.sheetHeight < this.closingThreshold) {
        this.dragEnd(e)
        this.sheetValue = false
      }
    }
  }

  beforeDestroy () : void {
    document.documentElement.classList.remove('no-scroll')
  }
}
