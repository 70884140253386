
import { Component, Prop, Vue } from 'vue-property-decorator'
import IContractService from '@/services/ContractService/IContractService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import i18n from '@/plugins/i18n'
import Page from '@/pages/Page'
import PartouLoader from '@/segments/PartouLoader/PartouLoader.vue'
import PartouShieldImage from '@/segments/PartouShieldImage/PartouShieldImage.vue'
import PartouButton from '@/segments/PartouButtons/PartouButton.vue'
import PartouSupportButtons from '@/segments/PartouSupportButtons/PartouSupportButtons.vue'
import { GetContractStatusOutput, Maybe } from '@/models'

@Component({
  components: { PartouLoader, PartouShieldImage, PartouButton, PartouSupportButtons },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})

export default class SignContractPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.signContract').toString()

  @Prop({ required: true })
  bookingHash!: string

  @Prop({ required: false, default: 'false' })
  isSigned?: string

  isLoading = true
  contractService! : IContractService
  contractStatus: Maybe<GetContractStatusOutput> = null

  homePage: string = process.env.VUE_APP_PARTOU_HOME_PAGE_PLACEHOLDER! /* Must be set */// eslint-disable-line @typescript-eslint/no-non-null-assertion

  created () : void {
    this.contractService = container.get<IContractService>(SERVICE_IDENTIFIERS.IContractService)
    this.fetchContractStatusAsync()
  }

  async fetchContractStatusAsync () : Promise<void> {
    this.contractStatus = await this.contractService.getContractStatusAsync(this.isSigned === 'true', { hash: this.bookingHash })
    this.isLoading = false
  }

  async onSignClickAsync () : Promise<void> {
    if (this.contractStatus && this.contractStatus.inviteUrl && !this.contractStatus.isSigned) {
      window.open(this.contractStatus.inviteUrl, '_self')
    }
  }

  get showSadGirl () :boolean {
    return !this.isLoading && (this.contractStatus === null || this.contractStatus === undefined || this.contractStatus.isDeclined || this.contractStatus.isExpired)
  }

  goToHome () : void {
    window.location.href = this.homePage
  }
}
