import IPropositionService from '@/services/PropositionService/IPropositionService'
import IOfferService from '@/services/OfferService/IOfferService'
import IChildBenefitCalculatorService from '@/services/ChildBenefitCalculatorService/IChildBenefitCalculatorService'
import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import IPropositionState from './IPropositionState'
import { MUTATIONS } from './propositionMutations'
import { GetOfferBySlugQueryVariables, GetPropositionsQueryVariables, GetPropositionSubscriptionsQueryVariables, Proposition, ServiceKind, ServiceVarietyDaySelection, ServiceVarietyName } from '@/models'
import { timeBetween, unitOfTime } from '@/utils/dateUtils'
import moment from 'moment'
import DayOfWeek, { parseDayOfWeekToNumber } from '@/models/enums/DayOfWeek'
import { determineTypeOfCare } from '@/models/enums/ServiceKind'
import { NAMESPACE as USERSELECTION_NAMESPACE, GETTERS as USERSELECTION_GETTERS } from '@/store/modules/userSelection'

// Workaround for handling closed days
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DayCheckboxType from '@/components/InputFields/DaySelector//DayCheckboxType'
import IWaitingListService from '@/services/WaitingListService/IWaitingListService'
import IBookingService from '@/services/BookingService/IBookingService'
import DaySelectionState from '@/models/types/DaySelectionState'
import IServiceSettingsService from '@/services/ServiceSettingsService/IServiceSettingsService'

export const ACTIONS = {
  getPropositionsAsync: 'getPropositionsAsync',
  getPropositionsByGeoLocationAsync: 'getPropositionsByGeoLocationAsync',
  getPropositionSubscriptionsAsync: 'getPropositionSubscriptionsAsync',
  getPropositionBySlugAsync: 'getPropositionBySlugAsync',
  getWaitingListPropositionStatusAsync: 'getWaitingListPropositionStatusAsync',
  acceptProposedWaitingListPropositionAsync: 'acceptProposedWaitingListPropositionAsync',
  getAcceptProposedWaitingListPropositionResponseAsync: 'getAcceptProposedWaitingListPropositionResponseAsync',
  bookPropositionAsync: 'bookPropositionAsync',
  getBookPropositionResponseAsync: 'getBookPropositionResponseAsync',
  resetStateAsync: 'resetStateAsync'
}

function getSelectedDaysOfWeekPerServiceVariety (selectedDays: Record<ServiceVarietyName, DayCheckboxState[]>, type: DayCheckboxType) : Record<ServiceVarietyName, DayOfWeek[]> {
  return {
    KDV: getSelectedDaysOfWeek(selectedDays.KDV, type),
    VSO: getSelectedDaysOfWeek(selectedDays.VSO, type),
    NSO: getSelectedDaysOfWeek(selectedDays.NSO, type)
  }
}

function getSelectedDaysOfWeek (selectedDays: DayCheckboxState[], type: DayCheckboxType) : DayOfWeek[] {
  return selectedDays.filter(x => x.isChecked && x.type === type).map(x => x.day)
}

// NOTE: this  does not exactly do what it says it does (legacy), but is only used to filtered the used openings, so leave it be
// (end date of care should be calculated from the birthdate, not from start date of care)
function calculateEndDateOfDayCare (dateOfBirth: Date, startDateOfDayCare: Date) : Date {
  const age = Math.floor(timeBetween(dateOfBirth, startDateOfDayCare, unitOfTime.year))
  const maximumAgeOfSchoolCare = 13
  const maximumAgeOfDayCare = 4
  if (age >= maximumAgeOfDayCare) {
    return moment(startDateOfDayCare).add(maximumAgeOfSchoolCare - age, 'years').toDate()
  } else {
    return moment(startDateOfDayCare).add(maximumAgeOfDayCare - age, 'years').toDate()
  }
}

export async function getRequestedProductIdsAsync (serviceId: string, startDateOfDayCare: Date | undefined, offerId: string, availableDays: Record<ServiceVarietyName, DayOfWeek[]>, waitinglistDays: Record<ServiceVarietyName, DayOfWeek[]>, childBenefitCalculatorService : IChildBenefitCalculatorService, serviceSettingsService : IServiceSettingsService) : Promise<string[] | undefined> {
  if (!serviceId || !startDateOfDayCare) {
    return undefined
  }

  const serviceSettings = await serviceSettingsService.getServiceSettingsByServiceIdAsync(serviceId)
  if (!serviceSettings?.useFlexkidsProducts) {
    return undefined
  }

  const products = await childBenefitCalculatorService.getChildBenefitCalculatorProductsAsync({
    offerId,
    referenceDate: startDateOfDayCare
  })

  if (!products) {
    return undefined
  }

  const requestedProductIds: string[] = []
  Object.values(ServiceVarietyName).forEach(serviceVariety => {
    const product = products[serviceVariety]
    if ((availableDays[serviceVariety]?.length || waitinglistDays[serviceVariety]?.length) && product) {
      requestedProductIds.push(product)
    }
  })

  return requestedProductIds
}

export function actions (waitingListService : IWaitingListService, propositionService : IPropositionService, offerService : IOfferService, bookingService : IBookingService, childBenefitCalculatorService : IChildBenefitCalculatorService, serviceSettingsService : IServiceSettingsService): ActionTree<IPropositionState, IState> {
  return {
    async getPropositionsAsync ({ commit, rootGetters, rootState }) {
      const { dateOfBirth, startDateOfDayCare, postalCode, daysPerServiceVariety, selectedSchool } = rootState.userSelection.formState
      if (dateOfBirth && startDateOfDayCare && postalCode?.latitude != null && postalCode.longitude != null) {
        const endDateOfDayCare = calculateEndDateOfDayCare(dateOfBirth, startDateOfDayCare)
        const serviceKindBasedOnDate = determineTypeOfCare(dateOfBirth, startDateOfDayCare)
        const getUniqueSelectedDays : () => DayOfWeek[] = rootGetters[`${USERSELECTION_NAMESPACE}/${USERSELECTION_GETTERS.getUniqueSelectedDays}`]
        const selectedDays = getUniqueSelectedDays().map(x => parseDayOfWeekToNumber(x))
        const selectedServiceVarieties : ServiceVarietyName[] = serviceKindBasedOnDate === ServiceKind.DayCare ? [ServiceVarietyName.KDV] : [ServiceVarietyName.VSO, ServiceVarietyName.NSO]

        const serviceVarietyDaySelection: ServiceVarietyDaySelection = {}
        selectedServiceVarieties.forEach(x => {
          const checkedDaysForServiceVariety = daysPerServiceVariety[x]?.filter(x => x.isChecked)
          serviceVarietyDaySelection[x] = checkedDaysForServiceVariety?.map(y => { return { DayOfWeek: y.day, Priority: y.priority } })
        })

        const propositionsQueryVariables = {
          latitude: postalCode.latitude,
          longitude: postalCode.longitude,
          birthDate: moment(dateOfBirth).toISOString(),
          startDateOfCare: moment(startDateOfDayCare).toISOString(),
          endDateOfCare: moment(endDateOfDayCare).toISOString(),
          daysOfWeek: selectedDays,
          selectedServiceVarietyNames: selectedServiceVarieties,
          serviceVarietyDaySelection
        } as GetPropositionsQueryVariables

        if (selectedSchool && selectedServiceVarieties?.some((selectedServiceVariety) => selectedServiceVariety !== ServiceVarietyName.KDV)) {
          propositionsQueryVariables.selectedSchool = {
            id: selectedSchool.id,
            addressLine1: selectedSchool.addressLine1,
            addressLine2: selectedSchool.addressLine2,
            locality: selectedSchool.locality,
            name: selectedSchool.name,
            postalCode: selectedSchool.postalCode
          }
        }
        const data = await propositionService.getPropositionsAsync(propositionsQueryVariables)
        commit(MUTATIONS.setPropositions, data)
      }
    },
    async getPropositionsByGeoLocationAsync ({ commit, rootState }, payload: { serviceKind?: ServiceKind, schoolId?: string}) {
      const maxDistance = process.env.VUE_APP_MAX_PROPOSITION_DISTANCE
      const { postalCode } = rootState.userSelection.formState
      const data = await offerService.getOffersByGeoLocationAsync({
        latitude: postalCode?.latitude,
        longitude: postalCode?.longitude,
        maxDistance,
        serviceKind: payload.serviceKind,
        schoolId: payload.schoolId
      })

      data.forEach(offer => {
        (offer.offerDistanceOffer as any).distance = offer.distance // eslint-disable-line @typescript-eslint/no-explicit-any
      })

      commit(MUTATIONS.setPropositions, data.map(o => o.offerDistanceOffer))
    },
    async getWaitingListPropositionStatusAsync ({ commit }, bookingHash : string) {
      const status = await waitingListService.getWaitingListStatusByHashAsync(bookingHash)
      commit(MUTATIONS.setWaitingListPropositionStatus, status)
    },
    async getPropositionSubscriptionsAsync ({ commit, rootState, rootGetters }, offer: { offerId: string, productIds?: string[] }) {
      const offerId = offer.offerId
      const productIds = offer.productIds
      const { startDateOfDayCare } = rootState.userSelection.formState
      const proposition = rootState.proposition.propositions?.find(x => x.offerId === offerId) ?? await offerService.getOfferByIdAsync({
        id: offerId,
        dateOfOpeningTime: startDateOfDayCare
      }) as Proposition
      const getDayCheckboxState : (withAvailability: boolean, withOpeningHours: boolean, offerId: string) => Record<ServiceVarietyName, DayCheckboxState[]> =
        rootGetters[`${USERSELECTION_NAMESPACE}/${USERSELECTION_GETTERS.getDayCheckboxState}`]
      const selectedDays = getDayCheckboxState(true, true, offerId)
      const availableDays = getSelectedDaysOfWeekPerServiceVariety(selectedDays, DayCheckboxType.Check)
      const waitingListDays = getSelectedDaysOfWeekPerServiceVariety(selectedDays, DayCheckboxType.Wait)

      commit(MUTATIONS.setPropositionSubscriptions, { offerId, propositionSubscriptions: [] })
      if ((!availableDays.KDV || (availableDays.KDV?.length <= 0 && waitingListDays.KDV?.length <= 0)) &&
          (!availableDays.VSO || (availableDays.VSO?.length <= 0 && waitingListDays.VSO?.length <= 0)) &&
          (!availableDays.NSO || (availableDays.NSO?.length <= 0 && waitingListDays.NSO?.length <= 0))) {
        return
      }

      // Use the given product(s) for price calculation, or retrieve the product(s) set to be used for the child benefit
      // calculator when no products are provided.
      let requestedProductIds = productIds
      if (requestedProductIds === undefined || requestedProductIds.length === 0) {
        requestedProductIds = await getRequestedProductIdsAsync(proposition?.service?.id, startDateOfDayCare, offerId, availableDays, waitingListDays, childBenefitCalculatorService, serviceSettingsService)
      }

      const payload = {
        offerId,
        dateOfBirth: moment(rootState.userSelection.formState.dateOfBirth).toISOString(),
        schoolGroupId: rootState.userSelection.formState.selectedSchoolGroup?.id ?? '',
        startDateOfCare: moment(startDateOfDayCare).toISOString(),
        availableDaysPerServiceVariety: availableDays,
        waitingListDaysPerServiceVariety: waitingListDays,
        requestedProductIds
      } as GetPropositionSubscriptionsQueryVariables

      // Subscriptions are being retrieved if the schoolgroup is not provided, but this is obliged for SchoolCare. Ignore this case and let it be empty
      if (proposition.kind !== ServiceKind.SchoolCare || payload.schoolGroupId.length > 0) {
        const data = await propositionService.getPropositionSubscriptionsAsync(payload)
        commit(MUTATIONS.setPropositionSubscriptions, { offerId: payload.offerId, propositionSubscriptions: data })
      }
    },
    async getPropositionBySlugAsync ({ commit }, payload: GetOfferBySlugQueryVariables) {
      const data = await offerService.getOfferBySlugAsync(payload)
      if (data) {
        commit(MUTATIONS.updateProposition, data)
      }
    },
    async acceptProposedWaitingListPropositionAsync ({ commit, rootState }, payload: { bookingHash : string, days: Partial<Record<ServiceVarietyName, DaySelectionState[]>>, removeFromWaitingListDaysPerServiceVariety: Record<ServiceVarietyName, DayOfWeek[]>, causedByReasonType: string | undefined, causedByReasonComment: string | undefined}) {
      commit(MUTATIONS.setAcceptProposedWaitingListPropositionRequest, {})
      const acceptWaitingListRequest = await waitingListService.acceptProposedWaitingListPropositionAsync(payload.bookingHash, payload.days, rootState.userSelection.formState, rootState.contactDetails.formState, payload.removeFromWaitingListDaysPerServiceVariety, payload.causedByReasonType, payload.causedByReasonComment)
      commit(MUTATIONS.setAcceptProposedWaitingListPropositionRequest, acceptWaitingListRequest)
      const responsePromise = waitingListService.getAcceptProposedWaitingListPropositionResponseAsync(acceptWaitingListRequest)
      commit(MUTATIONS.setAcceptProposedWaitingListPropositionState, { responseAsync: responsePromise })

      responsePromise.then(() => commit(MUTATIONS.setAcceptProposedWaitingListPropositionState, { pending: false }))
        .catch(() => commit(MUTATIONS.setAcceptProposedWaitingListPropositionState, { pending: false }))
    },
    async bookPropositionAsync ({ commit, rootState }, payload: {proposition: Proposition, checkBoxDays: Record<ServiceVarietyName, DayCheckboxState[]> }) {
      commit(MUTATIONS.setBookPropositionRequest, {})
      const bookPropositionRequest = await bookingService.bookPropostionRequestAsync(payload.proposition, rootState.userSelection.formState, rootState.contactDetails.formState, payload.checkBoxDays)
      commit(MUTATIONS.setBookPropositionRequest, bookPropositionRequest)

      const responsePromise = bookingService.getBookPropositionResponseAsync(bookPropositionRequest)
      commit(MUTATIONS.setBookPropositionState, { responseAsync: responsePromise })

      responsePromise.then(() => commit(MUTATIONS.setBookPropositionState, { pending: false }))
        .catch(() => commit(MUTATIONS.setBookPropositionState, { pending: false }))
    },
    async getBookPropositionResponseAsync ({ commit, rootState }) {
      const bookPropositionState = rootState.proposition.bookPropositionState?.request
      if (!bookPropositionState) {
        return
      }

      const responsePromise = bookingService.getBookPropositionResponseAsync(bookPropositionState)
      commit(MUTATIONS.setBookPropositionState, { responseAsync: responsePromise })

      responsePromise.then(() => commit(MUTATIONS.setBookPropositionState, { pending: false }))
        .catch(() => commit(MUTATIONS.setBookPropositionState, { pending: false }))
    },
    async getAcceptProposedWaitingListPropositionResponseAsync ({ commit, rootState }) {
      const acceptProposedWaitingListPropositionState = rootState.proposition.acceptProposedWaitingListPropositionState?.request
      if (!acceptProposedWaitingListPropositionState) {
        return
      }

      const responsePromise = waitingListService.getAcceptProposedWaitingListPropositionResponseAsync(acceptProposedWaitingListPropositionState)
      commit(MUTATIONS.setAcceptProposedWaitingListPropositionState, { responseAsync: responsePromise })

      responsePromise.then(() => commit(MUTATIONS.setAcceptProposedWaitingListPropositionState, { pending: false }))
        .catch(() => commit(MUTATIONS.setAcceptProposedWaitingListPropositionState, { pending: false }))
    },
    async resetStateAsync ({ commit }) {
      commit(MUTATIONS.resetState)
    }
  }
}
