
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import ChildBenefitCalculator from '@/components/ChildBenefitCalculator/ChildBenefitCalculator.vue'
import PropositionSubscription from '@/models/PropositionSubscription'
import { Proposition } from '@/models'
import FeatureDisclaimer from '../FeatureDisclaimer/FeatureDisclaimer.vue'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouCard, PartouButton, PartouFloatingCircleButton, ChildBenefitCalculator, PartouLoader, FeatureDisclaimer }
})
export default class PriceSummary extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @Prop({ required: true })
  proposition! : Proposition

  @Prop({ required: false })
  propositionSubscription! : PropositionSubscription

  @Prop({ required: false })
  allPropositionSubscriptions? : PropositionSubscription[]

  @Prop({ required: false, default: false })
  showUpperTitle!: boolean

  @Prop({ required: true })
  showFullSummary! : boolean

  @Prop({ required: false, default: false })
  isLoading! : boolean

  @Prop({ required: true })
  isOrderButtonDisabled! : boolean

  @Prop({ required: false, default: true })
  showWaitingListTip!: boolean

  @Prop({ required: false, default: false })
  small!: boolean

  @Prop({ required: false, default: false })
  showFeatureDisclaimer!: boolean

  @Prop({ required: false, default: true })
  showTitle!: boolean

  @Prop({ required: false, default: true })
  showCalculateButton!: boolean

  get costsPerMonth (): number {
    return this.propositionSubscription && this.propositionSubscription.priceOfFirstFullMonthForTotalDays
  }

  onOrderButtonClicked () : void {
    this.$emit('onOrderClicked')
  }
}
