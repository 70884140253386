import IUserSelectionFormState from './IUserSelectionFormState'

export const STATE = {
  formState: 'formState',
  currentStep: 'currentStep',
  formIsValid: 'formIsValid',
  isFastTrack: 'isFastTrack'
}

export default interface IUserSelectionState {
  formState: IUserSelectionFormState,
  currentStep: number,
  formIsValid: boolean,
  isFastTrack: boolean
}
