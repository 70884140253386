
import { Component, Vue } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import Page from '@/pages/Page'
import BirthAndStartDateSelectionForm from '@/components/AvailabilitySelector/BirthAndStartDateSelectionForm.vue'
import MultiStateDaySelector from '@/components/MultiStateDaySelector/MultiStateDaySelector.vue'
import { GetWaitingListStatusOutput, Maybe, Proposition, School, SchoolGroup, Service, ServiceKind, ServiceSettings, ServiceVarietyName, SubscriptionService as SubscriptionServiceModel } from '@/models'
import IPropositionService from '@/services/PropositionService/IPropositionService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { ACTIONS, GETTERS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import { SelectableSubscription } from '../OrderOverviewPage/Content/SubscriptionSelect/SelectableSubscription'
import PropositionSubscription from '@/models/PropositionSubscription'
import { eventBus } from '@/EventBus'
import SubscriptionService from '@/services/SubscriptionService/SubscriptionService'
import SubscriptionSelect from '../OrderOverviewPage/Content/SubscriptionSelect/SubscriptionSelect.vue'
import { ROUTES } from '@/router/routes'
import PriceSummary from '../OrderOverviewPage/Content/PriceSummary/PriceSummary.vue'
import PartouRadio from '@/components/PartouComponents/Input/PartouRadio/PartouRadio.vue'
import ProductService from '@/services/ProductService/ProductService'
import { ProductCategoryViewData } from '@/services/ProductService/ProductCategoryViewData'
import IConfigurationService from '@/services/ConfigurationService/IConfigurationService'
import ProductCategorySelect from '../OrderOverviewPage/Content/ProductCategorySelect/ProductCategorySelect.vue'
import ServiceNotBookable from '../OrderDirectPage/ServiceNotBookable/ServiceNotBookable.vue'
import { parseTimeStringToSeconds } from '@/utils/dateUtils'
import i18n from '@/plugins/i18n'
import DaySelectionState from '@/models/types/DaySelectionState'
import { sendChoiceTrackRegistrationAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import WaitingListPreferenceSelect from '../OrderDirectPage/WaitingListPreferenceSelect/WaitingListPreferenceSelect.vue'
import ServiceSettingsService from '@/services/ServiceSettingsService/ServiceSettingsService'

const propositionModule = namespace(NAMESPACES.proposition)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouFloatingCircleButton, PartouButton, BirthAndStartDateSelectionForm, MultiStateDaySelector, SubscriptionSelect, PriceSummary, PartouRadio, ProductCategorySelect, ServiceNotBookable, WaitingListPreferenceSelect },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class ProductAndWaitingListPreferencePage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.orderDirect').toString()

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @propositionModule.State(STATE.proposition.waitinglistPropositionStatus)
  waitinglistPropositionStatus?: GetWaitingListStatusOutput

  @propositionModule.State(STATE.proposition.propositions)
  propositions?: Proposition[]

  @propositionModule.Action(ACTIONS.proposition.getPropositionsAsync)
  getPropositionsAsync!: () => Promise<void>

  @propositionModule.Action(ACTIONS.proposition.getPropositionSubscriptionsAsync)
  getPropositionSubscriptionsAsync!: (offer: { offerId: string, productIds?: string[] }) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceAsync)
  setSelectedServiceAsync!: (selectedService: Service | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedPropositionId)
  setSelectedPropositionId!: (selectedPropositionId: string) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolAsync)
  setSelectedSchoolAsync!: (selectedSchool: School | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolGroupAsync)
  setSelectedSchoolGroupAsync!: (selectedSchoolGroup: SchoolGroup | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSubscriptionServiceAsync)
  setSelectedSubscriptionServiceAsync!: (selectedSubscription: Partial<SubscriptionServiceModel>) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @propositionModule.Getter(GETTERS.proposition.getPropositionBySlug)
  getPropositionBySlug!: (serviceSlug: string) => Proposition | undefined

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setPostalCode)
  setPostalCode!: ({ postcode, longitude, latitude }: { postcode: string, longitude: number, latitude: number }) => void

  @propositionModule.Getter(GETTERS.proposition.getPropositionSubscriptionsForOffer)
  getPropositionSubscriptionsForOffer!: (offerId: string) => PropositionSubscription[] | undefined

  @userSelectionModule.Action(ACTIONS.userSelection.setFastTrack)
  setFastTrack!: (fastTrack: boolean) => void

  @userSelectionModule.Action(ACTIONS.userSelection.setServiceVarietyDaysAsync)
  setServiceVarietyDaysAsync!: (input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) => Promise<void>

  serviceSettings: ServiceSettings | undefined

  selectableSubscriptions: SelectableSubscription[] = []
  selectedSubscription: SelectableSubscription | undefined
  selectedSchool: School | null = null
  schoolGroups: SchoolGroup[] | undefined
  selectedSchoolGroup: SchoolGroup | undefined

  propositionService!: IPropositionService
  productCategories: ProductCategoryViewData[] = []

  showWrongDatesWarning = false
  showCareTypeTransitionWarning = false
  showNoSchoolWarning = false
  showNoDatesWarning = false
  showNoDaysWarning = false
  datesValid = true

  birthAndStartDateKey = 0

  async mounted () : Promise<void> {
    this.propositionService = container.get(SERVICE_IDENTIFIERS.IPropositionService)
    const serviceSettingsService : ServiceSettingsService = container.get(SERVICE_IDENTIFIERS.IServiceSettingsService)

    this.serviceSettings = await serviceSettingsService.getServiceSettingsByServiceIdAsync(this.proposition?.id ?? '')

    this.selectableSubscriptions = await this.createSelectableSubscriptionsAsync()
    if (!this.serviceSettings.useFlexkidsProducts && this.selectableSubscriptions.length === 0) {
      await this.setSelectedSubscriptionServiceAsync(this.selectableSubscriptions.filter(s => s.subscriptionCode === 'AllWeeks')[0].subscriptionService)
    }

    if (this.serviceSettings.useFlexkidsProducts) {
      await this.getProductsAsync()
    }
  }

  get proposition (): Proposition | undefined {
    return this.propositions?.filter(p => p.id === this.userSelectionFormState.selectedPropositionId)[0]
  }

  get serviceAddress (): string {
    return this.proposition?.addressLine1 ?? ''
  }

  get isUsingFlexKidsProducts (): boolean {
    return this.serviceSettings?.useFlexkidsProducts ?? false
  }

  get isBso (): boolean {
    return this.userSelectionFormState.selectedService?.kind === ServiceKind.SchoolCare
  }

  getPropositionSubscription (): Maybe<PropositionSubscription> {
    if (this.selectedSubscription !== undefined) {
      return this.selectedSubscription.propositionSubscription
    }
    return this.proposition?.propositionSubscriptions?.[0]
  }

  async onSubscriptionSelectedAsync (selectableSubscription: SelectableSubscription) {
    this.selectedSubscription = selectableSubscription
    await this.setSelectedSubscriptionServiceAsync(selectableSubscription.subscriptionService)
  }

  // SUBSCRIPTION START

  async getActiveSubscriptions (): Promise<Partial<SubscriptionServiceModel>[]> {
    if (this.proposition && this.proposition.id) {
      const subscriptionService: SubscriptionService = container.get(SERVICE_IDENTIFIERS.ISubscriptionService) // Note: @inject does not work for some reason, so this is a workaround
      return subscriptionService.getActiveSubscriptionsByServiceId({ serviceId: this.proposition.id })
    }

    return []
  }

  async getPropostionSubscriptions (): Promise<Maybe<PropositionSubscription[]>> {
    if (this.proposition && this.proposition.id) {
      await this.getPropositionSubscriptionsAsync({ offerId: this.proposition.id })
      return this.getPropositionSubscriptionsForOffer(this.proposition.id)
    }

    return []
  }

  async createSelectableSubscriptionsAsync (): Promise<SelectableSubscription[]> {
    const selectableSubscriptions: SelectableSubscription[] = []
    const subscriptionServices = await this.getActiveSubscriptions()
    const propositionSubscriptions = await this.getPropostionSubscriptions().catch(() => {
      eventBus.$emit('globalError')
    })

    subscriptionServices.forEach(subscriptionService => {
      if (propositionSubscriptions && propositionSubscriptions.length > 0 && subscriptionService?.subscription) {
        const propositionSubscription = propositionSubscriptions.find(y => y.referenceIds === subscriptionService.id)
        if (propositionSubscription) {
          selectableSubscriptions.push({ ...subscriptionService.subscription, propositionSubscription, isSelected: false, subscriptionService, isCustomSubscriptionEnabled: subscriptionService.service?.isCustomSubscriptionEnabled })
        }
      }
    })

    selectableSubscriptions.sort((a, b) => a.order - b.order)
    if (selectableSubscriptions.length > 0) {
      let index = selectableSubscriptions.findIndex(s => s.subscriptionService.id === this.userSelectionFormState.selectedSubscription?.id)
      if (index === -1) {
        index = 0
      }

      selectableSubscriptions[index].isSelected = true
      await this.onSubscriptionSelectedAsync(selectableSubscriptions[index])
    }
    return selectableSubscriptions
  }

  // END SUBSCRIPTION

  // PRODUCT START

  async getProductsAsync () {
    if (this.proposition && this.proposition.id) {
      const productService: ProductService = container.get(SERVICE_IDENTIFIERS.IProductService) // Note: @inject does not work for some reason, so this is a workaround
      const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)

      const productCategories = await productService.getActivatedServiceProducts(this.proposition?.id ?? '', this.userSelectionFormState.startDateOfDayCare ?? new Date())
      productCategories.sort((a, b) => a.name.localeCompare(b.name))
      productCategories.forEach(p => p.products.sort((a, b) =>
        parseTimeStringToSeconds(a.startTime) - parseTimeStringToSeconds(b.startTime) ||
        parseTimeStringToSeconds(a.endTime) - parseTimeStringToSeconds(b.endTime)
      ))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const productCategoryFeatures = await configurationService.getConfigurationByKeyAsync<any>({ key: 'product_category_features' })
      this.productCategories = productCategories.map(p => ({ ...p, features: productCategoryFeatures[p.name] }))
    } else {
      this.productCategories = []
    }
  }

  // END PRODUCT

  async onOrderClickedAsync () {
    if (this.proposition) {
      sendChoiceTrackRegistrationAnalyticsEvent(this.proposition, this.userSelectionFormState.waitingListPreference ?? false)
    }
    this.$router.push({ name: ROUTES.orderContactInfo, params: { serviceSlug: this.userSelectionFormState.selectedService?.slug ?? '' } })
  }

  onBackButtonClicked () {
    this.$router.push({ path: '/' + this.userSelectionFormState.selectedService?.mdmId + '/inschrijven' })
  }
}
