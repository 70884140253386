
import { GETTERS, NAMESPACES } from '@/store'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({ components: { PartouLoader } })
export default class ChildBenefitSummaryTable extends Vue {
  @Prop({ required: true })
  costsPerHour! : number

  @Prop({ required: true })
  costsPerMonth! : number

  @Prop({ required: true })
  benefitsPerHour! : number

  @Prop({ required: true })
  benefitsPerMonth! : number

  @Prop({ required: true })
  ownContributionPerMonthForAvailableDays! : number

  @Prop({ required: true })
  ownContributionPerMonthForTotalDays! : number

  @Prop({ required: true })
  ownContributionPerHour! : number

  @Prop({ required: true })
  careHoursPerMonth! : number

  @Prop({ required: false, default: false })
  showWarningText!: boolean

  @userSelectionModule.Getter(GETTERS.userSelection.atLeastOneDayIsSelected)
  atLeastOneDayIsSelected!: () => boolean

  @Emit('onBenefitAmountInfoClicked')
  onBenefitAmountInfoClicked () : void {
    // Emits onBenefitAmountInfoClicked
  }

  get hasDaysSelected (): boolean {
    return this.atLeastOneDayIsSelected()
  }
}
