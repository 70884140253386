
import { Component, Prop, Vue } from 'vue-property-decorator'
import Page from '@/pages/Page'
import OrderOverviewPage from './OrderOverviewPage.vue'
import OrderOverviewSelectProductPage from './OrderOverviewSelectProductPage.vue'
import { Proposition } from '@/models'
import { ACTIONS, GETTERS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import OfferService from '@/services/OfferService/OfferService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import ServiceSettingsService from '@/services/ServiceSettingsService/ServiceSettingsService'

const propositionModule = namespace(NAMESPACES.proposition)
const userSelectionModule = namespace(NAMESPACES.userSelection)

  @Component({
    components: {
      OrderOverviewPage,
      OrderOverviewSelectProductPage

    }
  })
export default class OrderOverviewWrapperPage extends Vue implements Page {
    @propositionModule.Getter(GETTERS.proposition.getPropositionById)
    getPropositionById!: (id: string) => Proposition | undefined

    @propositionModule.Action(ACTIONS.proposition.getPropositionsAsync)
    getPropositionsAsync!: () => Promise<void>

    @propositionModule.Mutation(MUTATIONS.proposition.updateProposition)
    updateProposition!: (proposition : Partial<Proposition>) => void

    @userSelectionModule.Action(ACTIONS.userSelection.setSelectedProductIdsForServiceAsync)
    setSelectedProductIdsForServiceAsync!: (selectableProductIds : string[]) => Promise<void>

    @userSelectionModule.State(STATE.userSelection.formState)
    userSelectionFormState!: IUserSelectionFormState

    @userSelectionModule.Action(ACTIONS.userSelection.setFastTrack)
    setFastTrack!: (fastTrack: boolean) => void

    pageTitle = ''
    metaDescription?: string | undefined
    @Prop({ required: false })
    bookingHash!: string

    @Prop({ required: true })
    serviceSlug!: string

    proposition? : Partial<Proposition>
    isLoadingProposition = false
    useFlexKidsProducts = false
    showLinkToPartouOffers = false
    hasKnownFlexKidsProductState = false

    async mounted () : Promise<void> {
      this.setFastTrack(false)
      await this.getPropositionOrOffer()
      await this.getserviceSettingsByServiceId()
      if (!this.useFlexKidsProducts) {
        await this.setSelectedProductIdsForServiceAsync([])
      }
    }

    async getPropositionOrOffer () : Promise<void> {
      this.isLoadingProposition = true

      const offerService : OfferService = container.get(SERVICE_IDENTIFIERS.IOfferService) // Note: @inject does not work for some reason, so this is a workaround
      this.proposition = await offerService.getOfferBySlugAsync({ slug: this.serviceSlug, dateOfOpeningTime: this.userSelectionFormState.startDateOfDayCare }) as Proposition
      if (this.proposition) {
        this.updateProposition(this.proposition)
      }

      this.isLoadingProposition = false
    }

    async getserviceSettingsByServiceId () : Promise<void> {
      const serviceSettingsService : ServiceSettingsService = container.get(SERVICE_IDENTIFIERS.IServiceSettingsService) // Note: @inject does not work for some reason, so this is a workaround
      const serviceSettings = await serviceSettingsService.getServiceSettingsByServiceIdAsync(this.proposition?.id ?? '')
      this.useFlexKidsProducts = serviceSettings.useFlexkidsProducts
      this.showLinkToPartouOffers = serviceSettings.showLinkToPartouOffers
      this.hasKnownFlexKidsProductState = true
    }
}
