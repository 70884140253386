import IState from '@/store/IState'
import { GetterTree } from 'vuex'
import { CalculatorGetterFunctions } from './CalculatorGetterFunctions'
import { ICalculatorGetterTree } from './ICalculatorGetterTree'
import ICalculatorState from './ICalculatorState'

export const GETTERS = {
  getMaximumHourlyRate: 'getMaximumHourlyRate',
  getDeclarableHourlyRate: 'getDeclarableHourlyRate',
  getDeclarableCareHoursPerMonth: 'getDeclarableCareHoursPerMonth',
  getCumulativeIncomePerYear: 'getCumulativeIncomePerYear',
  getCompensatedPercentage: 'getCompensatedPercentage',
  getBenefitsPerMonth: 'getBenefitsPerMonth',
  getBenefitsPerHour: 'getBenefitsPerHour',
  getOwnContributionPerMonthForAvailableDays: 'getOwnContributionPerMonthForAvailableDays',
  getOwnContributionPerMonthForTotalDays: 'getOwnContributionPerMonthForTotalDays',
  getOwnContributionPerHour: 'getOwnContributionPerHour',
  getRemainingDeclarableCareHoursPerMonth: 'getRemainingDeclarableCareHoursPerMonth',
  getCareHoursPerMonth: 'getCareHoursPerMonth'
}

export function getters (calculatorGetters: CalculatorGetterFunctions = new CalculatorGetterFunctions()): GetterTree<ICalculatorState, IState> & ICalculatorGetterTree<ICalculatorState> {
  return {
    getMaximumHourlyRate: (state: ICalculatorState) => calculatorGetters.getMaximumHourlyRate(state),
    getDeclarableHourlyRate: (state: ICalculatorState) => calculatorGetters.getDeclarableHourlyRate(state),
    getDeclarableCareHoursPerMonth: (state: ICalculatorState) => calculatorGetters.getDeclarableCareHoursPerMonth(state),
    getCumulativeIncomePerYear: (state: ICalculatorState) => calculatorGetters.getCumulativeIncomePerYear(state),
    getCompensatedPercentage: (state: ICalculatorState) => calculatorGetters.getCompensatedPercentage(state),
    getBenefitsPerMonth: (state: ICalculatorState) => calculatorGetters.getBenefitsPerMonth(state),
    getBenefitsPerHour: (state: ICalculatorState) => calculatorGetters.getBenefitsPerHour(state),
    getOwnContributionPerMonthForAvailableDays: (state: ICalculatorState) => calculatorGetters.getOwnContributionPerMonthForAvailableDays(state),
    getOwnContributionPerMonthForTotalDays: (state: ICalculatorState) => calculatorGetters.getOwnContributionPerMonthForTotalDays(state),
    getOwnContributionPerHour: (state: ICalculatorState) => calculatorGetters.getOwnContributionPerHour(state),
    getRemainingDeclarableCareHoursPerMonth: (state: ICalculatorState) => calculatorGetters.getRemainingDeclarableCareHoursPerMonth(state),
    getCareHoursPerMonth: () => calculatorGetters.getCareHoursPerMonth()
  }
}
