import { Commit } from 'vuex'
import IUserSelectionState from '../IUserSelectionState'
import { MUTATIONS } from '../userSelectionMutations'
import { ServiceKind } from '@/models'
import DaySelectionState from '@/models/types/DaySelectionState'
import { resetOrCopySelectedServiceVarietyDays } from '../userSelectionActions'
import ServiceVarietyName from '@/models/enums/ServiceVarietyName'

export function setDayCheckboxState (commit : Commit, state : IUserSelectionState, input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) {
  const serviceVarietyDays : Record<ServiceVarietyName, DaySelectionState[]> = {
    KDV: state.formState.daysPerServiceVariety.KDV ?? [],
    NSO: state.formState.daysPerServiceVariety.NSO ?? [],
    VSO: state.formState.daysPerServiceVariety.VSO ?? []
  }
  serviceVarietyDays[input.serviceVariety] = input.days.map(x => ({ day: x.day, isChecked: x.isChecked, priority: x.priority, type: x.type })) ?? []

  if (state.formState.selectedServiceKind === ServiceKind.SchoolCare) {
    serviceVarietyDays.NSO.forEach((x, index) => {
      if (!x.isChecked) {
        serviceVarietyDays.VSO[index].isChecked = false
      }
    })
  }
  commit(MUTATIONS.setServiceVarietyDaysAsync, serviceVarietyDays)

  const selectedServiceVarieties = []
  if (serviceVarietyDays.KDV.some(x => x.isChecked)) {
    selectedServiceVarieties.push(ServiceVarietyName.KDV)
  }

  if (serviceVarietyDays.VSO.some(x => x.isChecked)) {
    selectedServiceVarieties.push(ServiceVarietyName.VSO)
  }

  if (serviceVarietyDays.NSO.some(x => x.isChecked)) {
    selectedServiceVarieties.push(ServiceVarietyName.NSO)
  }

  commit(MUTATIONS.setFormState, resetOrCopySelectedServiceVarietyDays(state, selectedServiceVarieties as ServiceVarietyName[]))
}
