
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouCard extends Vue {
  @Prop({ required: false, default: 'rounded-xl' })
  roundingClass!: string

  @Prop({ required: false, default: true })
  useDefaultPadding!: boolean
}
