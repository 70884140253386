
import { Component, Vue, Prop } from 'vue-property-decorator'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import ProductTimeSelect from '../ProductTimeSelect/ProductTimeSelect.vue'
import { ProductCategoryViewData } from '@/services/ProductService/ProductCategoryViewData'
import { ProductViewData } from '@/services/ProductService/ProductViewData'
import { ACTIONS, GETTERS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import LinkToPartouOffers from '../LinkToPartouOffers/LinkToPartouOffers.vue'
import { ServiceVarietyName } from '@/models'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import ProductCategoryFeature from '@/models/ProductCategoryFeature'
import ProductCategory from '@/models/enums/ProductCategory'
import FeatureDisclaimer from '../FeatureDisclaimer/FeatureDisclaimer.vue'
import PartouRadioOn from '@/components/PartouComponents/Icons/PartouRadioOn.vue'
import PartouRadioOff from '@/components/PartouComponents/Icons/PartouRadioOff.vue'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouNotify, ProductTimeSelect, LinkToPartouOffers, FeatureDisclaimer, PartouRadioOn, PartouRadioOff }
})
export default class ProductCategorySelect extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedProductIds)
  getSelectedProductIds!: () => string[]

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedServiceVarieties)
  getSelectedServiceVarieties!: () => string[]

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedProductCategory)
  setSelectedProductCategory!: (productCategory : ProductCategory) => Promise<void>

  @Prop({ required: true, default: () => [] })
  productCategories!: ProductCategoryViewData[]

  @Prop({ required: false, default: true })
  showPricing!: boolean

  @Prop({ required: false, default: false })
  showLinkToPartouOffers!: boolean

  @Prop({ required: false, default: false })
  displayVSOProducts!: boolean

  @Prop({ required: false, default: true })
  showFeatureDisclaimer!: boolean

  @Prop({ required: false, default: true })
  showTitle!: boolean

  serviceVarietiesInProposition: ServiceVarietyName[] = []
  showFeatureInfo = false
  featureTitle = ''
  featureDescription = ''
  selectedCategoryIndex = 0
  selectedCategory: string | undefined = ''
  uniqueCategories: (string | undefined)[] = []
  selectableProducts: ProductViewData[] = []

  mounted () {
    this.serviceVarietiesInProposition = this.userSelectionFormState.selectedServiceVarieties
    const ids = this.getSelectedProductIds()
    this.selectedCategoryIndex = Math.max(0, this.productCategories.findIndex(cat =>
      cat.products.some(product => ids.indexOf(product.id) !== -1 && product.serviceVariety !== ServiceVarietyName.VSO)
    ))

    this.selectedCategory = this.productCategories[this.selectedCategoryIndex].name
    this.selectableProducts = this.productCategories[this.selectedCategoryIndex].products
    this.updateSelectedProductCategory()
  }

  showFeature (feature: ProductCategoryFeature): boolean {
    const featureIsForSelectedServiceVariety = feature.availableForServiceVarieties !== undefined && feature.availableForServiceVarieties.some((x: ServiceVarietyName) => this.serviceVarietiesInProposition.includes(x))
    return featureIsForSelectedServiceVariety
  }

  isCheapestCategory (index: number): boolean {
    const cheapestPrice = this.getHourRate(index)
    return this.productCategories.every((cat, i) =>
      i === index || this.getHourRate(i) >= cheapestPrice
    )
  }

  formatHourRate (hourRate : number) : string {
    return `€ ${(hourRate / 100).toFixed(2).replace('.', ',')}`
  }

  async onFeatureInfoCancelled () : Promise<void> {
    this.showFeatureInfo = false
    await new Promise(resolve => setTimeout(resolve, 200))
    this.featureTitle = ''
    this.featureDescription = ''
  }

  onFeatureInfoClicked (title : string, description: string) : void {
    this.featureTitle = title
    this.featureDescription = description
    this.showFeatureInfo = true
  }

  onCategorySelected (index : number) {
    this.selectedCategoryIndex = index
    this.selectedCategory = this.productCategories[index].name
    this.selectableProducts = this.productCategories[index].products.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
    this.updateSelectedProductCategory()
  }

  getHourRate (index : number): number {
    const selectedServiceVarieties = this.getSelectedServiceVarieties()

    let serviceVariety = ServiceVarietyName.KDV
    if (selectedServiceVarieties.includes(ServiceVarietyName.NSO)) {
      serviceVariety = ServiceVarietyName.NSO
    }

    if (index === this.selectedCategoryIndex) {
      return this.getSelectedProductIds().reduce((price: number, productId: string) => {
        return this.productCategories[index].products.find(p => p.id === productId && p.serviceVariety !== ServiceVarietyName.VSO)?.pricePerHour ?? price
      }, 0)
    } else {
      const productsOfServiceVariety = this.productCategories[index].products.filter(x => x.serviceVariety === serviceVariety)
      return productsOfServiceVariety[0].pricePerHour
    }
  }

  updateSelectedProductCategory (): void {
    if (this.selectedCategory && this.selectedCategory in ProductCategory) {
      this.setSelectedProductCategory(this.selectedCategory as ProductCategory)
    }
  }
}
