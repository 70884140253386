import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import { DayOfWeek, DaySelection, Proposition, School, SchoolGroup, ServiceVarietyName } from '@/models'
import DaySelectionState from '@/models/types/DaySelectionState'
import IContactDetailCareTaker from '@/store/modules/contactDetails/IContactDetailCareTaker'
import IContactDetailChild from '@/store/modules/contactDetails/IContactDetailChild'
import { capitalizeFirstLetters, encryptStringAsync } from '@/utils/stringUtils'

export type ReservationPayload = {
  StartDate: string
  SelectedDaysReservation: DaySelection[]
  SelectedDaysWaitingList: DaySelection[]
  OfferId: string
}

export type ChildPayload = {
  FirstName: string
  MiddleName?: string
  LastName: string
  Gender: string
  DateOfBirth: string
  EncryptedBSN: string
}

export type CareTakerPayload = {
  Initials: string
  FirstName: string
  MiddleName?: string
  LastName: string
  DateOfBirth: string
  EncryptedBSN: string
  Address: {
    Street: string
    PostalCode: string
    Locality: string
    HouseNumber: string
    HouseNumberAddition?: string
  }
  ContactInfo: {
    Email: string
    PhoneNumber: string
  }
}

export type SchoolPayload = {
  Id: string
  Name?: string,
  SchoolGroupId: string,
  SchoolGroupName?: string
}

export type WaitingListDaysPerServiceVarietyPayload = {
  [ServiceVarietyName: string]: string[]
}

export async function buildChildPayloadAsync (child: IContactDetailChild): Promise<ChildPayload> {
  return {
    FirstName: capitalizeFirstLetters(child.firstName),
    MiddleName: capitalizeFirstLetters(child.middleName),
    LastName: capitalizeFirstLetters(child.lastName),
    DateOfBirth: child.dateOfBirth.toISOString ? child.dateOfBirth.toISOString() : child.dateOfBirth.toString(), // TODO: KF-1210: Date might be a string since dates dont get mapped properly when reading from the sessionStorage,
    Gender: child.gender,
    EncryptedBSN: child.bsn ? await encryptStringAsync(child.bsn) : ''
  }
}

export async function buildCareTakerPayloadAsync (careTaker: IContactDetailCareTaker): Promise<CareTakerPayload> {
  return {
    Initials: careTaker.initials,
    FirstName: capitalizeFirstLetters(careTaker.firstName),
    MiddleName: capitalizeFirstLetters(careTaker.middleName),
    LastName: capitalizeFirstLetters(careTaker.lastName),
    DateOfBirth: careTaker.dateOfBirth?.toISOString ? careTaker.dateOfBirth?.toISOString() : careTaker.dateOfBirth!.toString(), // eslint-disable-line @typescript-eslint/no-non-null-assertion
    EncryptedBSN: await encryptStringAsync(careTaker.bsn),
    Address: {
      Street: careTaker.street,
      PostalCode: careTaker.postalCode,
      Locality: careTaker.locality,
      HouseNumber: careTaker.houseNumber,
      HouseNumberAddition: careTaker.houseNumberAddition || ''
    },
    ContactInfo: {
      Email: careTaker.emailAddress,
      PhoneNumber: careTaker.phoneNumber
    }
  }
}

export function buildSchoolPayload (selectedSchool?: School, selectedSchoolGroup?: SchoolGroup): SchoolPayload | undefined {
  if (!selectedSchool || !selectedSchoolGroup) {
    return undefined
  }

  return {
    Id: selectedSchool.id,
    Name: selectedSchool.name,
    SchoolGroupId: selectedSchoolGroup.id,
    SchoolGroupName: selectedSchoolGroup?.name
  }
}

export function buildSelectedDaysPayloadAsync (days: Partial<Record<ServiceVarietyName, DaySelectionState[]>>) : DaySelection[] {
  const selectedDays: Set<DaySelection> = new Set<DaySelection>()
  for (const [key, value] of Object.entries(days)) {
    if (value) {
      const checkedDays = value?.filter((checkbox) => checkbox.isChecked)
      checkedDays.forEach(dayCheckboxState => {
        const day = dayCheckboxState.day
        selectedDays.add({ SelectedDay: day, ServiceVarietyName: key })
      })
    }
  }
  return Array.from(selectedDays.values())
}

export function buildRemoveFromWaitingListDaysPerServiceVarietyPayloadAsync (days: Partial<Record<ServiceVarietyName, DayOfWeek[]>>) : WaitingListDaysPerServiceVarietyPayload {
  return Object.entries(days).reduce((acc, [serviceVariety, days]) => {
    acc[serviceVariety] = days.map(day => DayOfWeek[day as keyof typeof DayOfWeek])
    return acc
  }, {} as WaitingListDaysPerServiceVarietyPayload)
}

export function buildReservationPayloadAsync (proposition: Proposition, startDateOfDayCare: Date, days: Record<ServiceVarietyName, DayCheckboxState[]>, waitingListPreference: boolean): ReservationPayload {
  const selectedReservationDays: Set<DaySelection> = new Set<DaySelection>()
  const selectedWaitingListDays: Set<DaySelection> = new Set<DaySelection>()
  for (const [key, value] of Object.entries(days)) {
    if (value) {
      const checkedDays = value?.filter((checkbox) => checkbox.isChecked && checkbox.type !== DayCheckboxType.Closed)
      checkedDays.forEach(dayCheckboxState => {
        const day = dayCheckboxState.day
        const serviceVarietyProposition = proposition.serviceVarietyPropositions?.find(x => x.serviceVarietyName === key)
        if (!waitingListPreference && serviceVarietyProposition?.availabilityPerDay[day]) {
          selectedReservationDays.add({ SelectedDay: day, ServiceVarietyName: key })
        } else {
          selectedWaitingListDays.add({ SelectedDay: day, ServiceVarietyName: key })
        }
      })
    }
  }

  return {
    StartDate: startDateOfDayCare.toISOString ? startDateOfDayCare.toISOString() : startDateOfDayCare.toString(), // TODO: KF-1210: Date might be a string since dates dont get mapped properly when reading from the sessionStorage
    SelectedDaysReservation: Array.from(selectedReservationDays.values()),
    SelectedDaysWaitingList: Array.from(selectedWaitingListDays.values()),
    OfferId: proposition.offerId ?? ''
  }
}
