
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import { getDateOfBirthMinDate, getStartDateOfCareMaxDate, getStartDateOfCareMinDate } from '@/utils/dateUtils'
import { namespace } from 'vuex-class'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import PostalCodeInput from '@/components/InputFields/PostalCodeInput/PostalCodeInput.vue'
import { ServiceKind } from '@/models'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const contactDetailModule = namespace(NAMESPACES.contactDetails)
const configurationModule = namespace(NAMESPACES.configuration)

@Component({ components: { PostalCodeInput, DateInputField, PartouNotify } })
export default class BirthAndStartDateSelectionForm extends Vue {
  @Prop({ required: true })
  withPostalCode!: boolean

  @Prop({ required: false, default: false })
  alignDatesFlexRow!: boolean

  @Ref('form')
  Form! : HTMLFormElement

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any

  @contactDetailModule.Action(ACTIONS.contactDetails.setChildDateOfBirth)
  setChildDateOfBirth!: (dateOfBirth : Date) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setDateOfBirth)
  setDateOfBirth!: (date : Date) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setStartDate)
  setStartDate!: (date : Date) => Promise<void>

  isValid = false
  showPostalCodeTooltip = false

  get dateOfBirthMinDate (): Date {
    return getDateOfBirthMinDate(this.userSelectionFormState.startDateOfDayCare)
  }

  get startDateOfCareMinDate () : Date | undefined {
    return getStartDateOfCareMinDate(this.userSelectionFormState.dateOfBirth)
  }

  get startDateOfCareMaxDate () : Date | undefined {
    return getStartDateOfCareMaxDate(this.userSelectionFormState.dateOfBirth)
  }

  get isKDV () : boolean {
    return this.userSelectionFormState.selectedService?.kind === ServiceKind.DayCare
  }

  mounted () : void {
    if (this.isValid && this.userSelectionFormState.dateOfBirth && this.userSelectionFormState.startDateOfDayCare) {
      this.onBothFilled()
    }
  }

  @Watch('userSelectionFormState', { deep: true, immediate: true })
  onUserSelectionFormStateChanged (): void {
    if (this.userSelectionFormState.dateOfBirth) {
      this.setChildDateOfBirth(this.userSelectionFormState.dateOfBirth)
    }
    this.$nextTick(() => this.validateFormAsync())
  }

  @Emit('onIsValidUpdated')
  @Watch('isValid')
  onIsValidUpdated () : boolean {
    return this.isValid
  }

  onStartDateChanged (value : Date) : void {
    this.setStartDate(value)
    if (this.isValid && this.userSelectionFormState.dateOfBirth && this.userSelectionFormState.startDateOfDayCare) {
      this.onBothFilled()
    }
  }

  onDateOfBirthChanged (value : Date) : void {
    this.setDateOfBirth(value)
    if (this.isValid && this.userSelectionFormState.dateOfBirth && this.userSelectionFormState.startDateOfDayCare) {
      this.onBothFilled()
    }
  }

  @Emit('onBothFilled')
  onBothFilled () : void {
    // emits onBothFilled event
  }

  // Form validates async, don't make this method sync without testing!
  async validateFormAsync (validateAllFields = false) : Promise<void> {
    if (validateAllFields ||
    (
      (this.withPostalCode || (this.userSelectionFormState.postalCode?.postcode && this.userSelectionFormState.postalCode?.postcode.length > 0)) &&
      this.userSelectionFormState.dateOfBirth && this.userSelectionFormState.startDateOfDayCare
    )) {
      this.Form.validate()
    }
  }
}
