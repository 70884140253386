import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import { DayOfWeek, ServiceKind, ServiceVarietyName } from '@/models'
import IState from '@/store/IState'
import { addBusinessDays, minimalBusinessDaysBeforeCareStartDate } from '@/utils/dateUtils'
import { GetterTree } from 'vuex'
import IUserSelectionState from './IUserSelectionState'
import { determineTypeOfCare } from '@/models/enums/ServiceKind'
import { SchoolValidationResponse } from './ValidatorEnums/SchoolValidationResponse'
import { getDayCheckboxState } from '@/store/modules/userSelection/getters/getDayCheckboxState'
import { getDayCheckboxStateWaitingListProposition } from '@/store/modules/userSelection/getters/getDayCheckboxStateWaitingListProposition'
import { isFormStateSpecifiedForServiceKind } from '@/store/modules/userSelection/getters/isFormStateSpecifiedForServiceKind'
import { getUniqueSelectedDays } from './getters/getUniqueSelectedDays'
import moment from 'moment'
import ProductCategory from '@/models/enums/ProductCategory'

export const GETTERS = {
  isFormStateSpecifiedForServiceKind: 'isFormStateSpecifiedForServiceKind',
  atLeastOneDayIsSelected: 'atLeastOneDayIsSelected',
  getUniqueSelectedDays: 'getUniqueSelectedDays',
  getDayCheckboxState: 'getDayCheckboxState',
  getDayCheckboxStateWaitingListProposition: 'getDayCheckboxStateWaitingListProposition',
  getIsValidForm: 'getIsValidForm',
  getIsValidSchoolLocality: 'getIsValidSchoolLocality',
  getIsValidSchool: 'getIsValidSchool',
  getIsValidSchoolGroup: 'getIsValidSchoolGroup',
  getFirstFullMonthAfterMinStartDate: 'getFirstFullMonthAfterMinStartDate',
  getIsBSOTransitionAge: 'getIsBSOTransitionAge',
  getCurrentSelectionStep: 'getCurrentSelectionStep',
  getServiceVarietiesFromProposition: 'getServiceVarietiesFromProposition',
  getSelectedProductCategory: 'getSelectedProductCategory',
  getSelectedProductIds: 'getSelectedProductIds',
  getSelectedServiceVarieties: 'getSelectedServiceVarieties'
}

export function getters (): GetterTree<IUserSelectionState, IState> {
  return {
    isFormStateSpecifiedForServiceKind: (state) => (validateDays = false, validateSchool = false, serviceKind?: ServiceKind) : boolean =>
      isFormStateSpecifiedForServiceKind(state.formState, validateDays, validateSchool, serviceKind),
    getUniqueSelectedDays: (state) => () : DayOfWeek[] =>
      getUniqueSelectedDays(state),
    getDayCheckboxState: (state, getters, rootState, rootGetters) => (withAvailability: boolean, withOpeningHours: boolean, offerId?: string, uncheckClosedDay = true) : Record<ServiceVarietyName, DayCheckboxState[]> =>
      getDayCheckboxState(state, rootGetters, withAvailability, withOpeningHours, offerId, uncheckClosedDay),
    getDayCheckboxStateWaitingListProposition: (state, getters, rootState) => () : Record<ServiceVarietyName, DayCheckboxState[]> =>
      getDayCheckboxStateWaitingListProposition(state, rootState),
    atLeastOneDayIsSelected: (state) => () : boolean => {
      const uniqueDays = getUniqueSelectedDays(state)
      return uniqueDays.length > 0
    },
    getIsBSOTransitionAge: (state, getter, rootState) => () : boolean => {
      if (!state.formState.dateOfBirth || !state.formState.startDateOfDayCare || !rootState.configuration.planSettings) {
        return false
      }
      const monthPeriod = rootState.configuration.planSettings.monthsOfTransitionPeriodBSO
      const minimumDate = moment(state.formState.startDateOfDayCare).add(-4, 'years')
      const maximumDate = moment(state.formState.startDateOfDayCare).add(monthPeriod, 'months').add(-4, 'years')

      return moment(state.formState.dateOfBirth).isBetween(minimumDate, maximumDate)
    },
    getCurrentSelectionStep: (state) => (resetCurrentStepTo = -1) : number => {
      if (resetCurrentStepTo !== -1) {
        return resetCurrentStepTo
      }

      if (!state.formState.dateOfBirth || !state.formState.startDateOfDayCare || !state.formState.postalCode) {
        return 0
      }
      if (determineTypeOfCare(state.formState.dateOfBirth, state.formState.startDateOfDayCare) === ServiceKind.SchoolCare && (!state.formState.selectedSchool || !state.formState.selectedSchoolLocality || !state.formState.selectedSchoolGroup)) {
        return 1
      }

      return 2
    },
    getSelectedProductCategory: (state) => () : ProductCategory | undefined => {
      return state.formState.selectedProductCategory
    },
    getSelectedProductIds: (state) => () : string[] => {
      return state.formState.selectedProductIds ?? []
    },
    getSelectedServiceVarieties: (state) => () : string[] => {
      return state.formState.selectedServiceVarieties ?? []
    },
    getIsValidSchoolLocality: (state) => () : boolean => {
      if (!state.formState.selectedSchoolLocality) {
        return false
      }
      return true
    },
    getIsValidSchool: (state) => () : SchoolValidationResponse => {
      switch (true) {
      case !state.formState.selectedSchool:
        return SchoolValidationResponse.NoSchoolSelected
      case state.formState.selectedSchool?.schoolServices.length === 0:
        return SchoolValidationResponse.NoActiveLocations
      case state.formState.selectedSchool?.schoolServices.filter(x => x.service.serviceSettings[0].visibleInSelectionGuide).length === 0 && !state.formState.isUsingChildbenefitCalculator:
        return SchoolValidationResponse.NoActiveLocations
      case state.formState.selectedSchool?.schoolGroups.length === 0:
        return SchoolValidationResponse.NoSchoolGroups
      case state.formState.selectedService && !state.formState.selectedSchool?.schoolServices.some(x => x.service.id === state.formState.selectedService?.id):
        return SchoolValidationResponse.NoRelationToSelectedLocation
      case state.formState.isUsingChildbenefitCalculator && state.formState.selectedSchool?.schoolGroups.filter(x => x.schoolGroupOpenings.length > 0).length === 0:
        return SchoolValidationResponse.NoSchoolGroupOpenings
      case state.formState.isAcceptingWaitinglist && state.formState.selectedSchool?.schoolGroups.filter(x => x.schoolGroupOpenings.length > 0).length === 0:
        return SchoolValidationResponse.NoSchoolGroupOpenings
      default:
        return SchoolValidationResponse.Success
      }
    },
    getIsValidSchoolGroup: (state, getters) => () : boolean => {
      if (!state.formState.selectedSchoolGroup) {
        return false
      }

      if (state.formState.isUsingChildbenefitCalculator) {
        if (state.formState.selectedSchoolGroup.schoolGroupOpenings.length === 0) {
          return false
        }

        const minStartDate = getters.getFirstFullMonthAfterMinStartDate(state)
        if (state.formState.selectedSchoolGroup.schoolGroupOpenings.filter(x => new Date(x.validFrom) < minStartDate && (x.validUntil == null || new Date(x.validUntil) > minStartDate)).length === 0) {
          return false
        }
      }

      return true
    },
    getIsValidForm: (state, getters, rootState) => () : boolean => {
      const selectedService = rootState.userSelection.formState.selectedService
      const selectedDays = getters.getDayCheckboxState(false, true, selectedService?.id)

      if (!rootState.userSelection.formIsValid || !rootState.childBenefitCalculator.formState.formIsValid || !selectedService || (!(selectedService.serviceSettings?.[0]?.useFlexkidsProducts ?? false) && !selectedService.servicePricings.length)) {
        return false
      }

      if (
        rootState.userSelection.formState.selectedServiceKind === ServiceKind.SchoolCare &&
        !selectedDays.NSO.some((x: DayCheckboxState) => (x.isChecked && x.type !== 'Closed'))
      ) {
        return false
      }

      if (
        rootState.userSelection.formState.selectedServiceKind === ServiceKind.DayCare &&
        !selectedDays.KDV.some((x: DayCheckboxState) => (x.isChecked && x.type !== 'Closed'))
      ) {
        return false
      }
      return true
    },
    getFirstFullMonthAfterMinStartDate: (state) => () : Date => {
      let startDate = state.formState.startDateOfDayCare
      if (!startDate) {
        startDate = new Date()
      }
      const offsetStartDate = addBusinessDays(startDate, minimalBusinessDaysBeforeCareStartDate)
      return moment(offsetStartDate).utc().add(1, 'M').startOf('month').toDate()
    },
    getServiceVarietiesFromProposition: (state, getters, rootState) => () : string[] => {
      const availableWaitingListDaysForServiceVarieties = rootState.proposition.waitinglistPropositionStatus?.availableWaitingListDays ? rootState.proposition.waitinglistPropositionStatus.availableWaitingListDays : {}
      return Object.keys(availableWaitingListDaysForServiceVarieties)
    }
  }
}
