
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouRadio from '@/components/PartouComponents/Input/PartouRadio/PartouRadio.vue'
import { ACTIONS, GETTERS, NAMESPACES } from '@/store'
import { namespace } from 'vuex-class'
import { ProductViewData } from '@/services/ProductService/ProductViewData'
import { ServiceVarietyName } from '@/models'
import { parseTimeStringToSeconds } from '@/utils/dateUtils'
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouRadio }
})
export default class ProductTimeSelect extends Vue {
  @Prop({ required: true, default: () => [] })
  products!: ProductViewData[]

  @Prop({ required: true, default: false })
  displayVSOProducts!: boolean

  @Prop({ required: false, default: true })
  showTitle!: boolean

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedProductIdsForServiceAsync)
  setSelectedProductIdsForServiceAsync!: (selectableProductIds : string[]) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedProductIds)
  getSelectedProductIds!: () => string[]

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedServiceVarieties)
  getSelectedServiceVarieties!: () => string[]

  isKDVVariety = true
  activeKdvProductId?: string = undefined
  activeVsoProductId?: string = undefined
  activeNsoProductId?: string = undefined
  nsoProducts: ProductViewData[] = []
  vsoProducts: ProductViewData[] = []
  serviceVarietyName = ServiceVarietyName

  @Watch('products')
  onProductsChange (): void {
    this.isKDVVariety = this.getSelectedServiceVarieties().indexOf(ServiceVarietyName.KDV) !== -1
    this.activeKdvProductId = undefined
    this.activeVsoProductId = undefined
    this.activeNsoProductId = undefined

    // For BSO split the products array in two sorted arrays of NSO and VSO products.
    if (!this.isKDVVariety) {
      this.nsoProducts = this.products.filter(p => p.serviceVariety === ServiceVarietyName.NSO).sort((a, b) => parseTimeStringToSeconds(a.endTime) - parseTimeStringToSeconds(b.endTime))
      this.vsoProducts = this.products.filter(p => p.serviceVariety === ServiceVarietyName.VSO).sort((a, b) => parseTimeStringToSeconds(a.startTime) - parseTimeStringToSeconds(b.startTime))
      this.activeNsoProductId = this.nsoProducts.length ? this.nsoProducts[0].id : undefined
      this.activeVsoProductId = this.displayVSOProducts && this.vsoProducts.length ? this.vsoProducts[0].id : undefined
    } else {
      this.activeKdvProductId = this.products[0].id
    }

    // Determine selected products.
    this.getSelectedProductIds().forEach((productId: string) => {
      const product = this.products.find(p => p.id === productId)
      if (product?.serviceVariety === ServiceVarietyName.KDV) {
        this.activeKdvProductId = productId
      } else if (this.displayVSOProducts && product?.serviceVariety === ServiceVarietyName.VSO) {
        this.activeVsoProductId = productId
      } else if (product?.serviceVariety === ServiceVarietyName.NSO) {
        this.activeNsoProductId = productId
      }
    })

    this.updateSelectedProducts()
  }

  /**
   * Get textual additions of the selected products. For BSO make sure that NSO text comes before VSO text.
   */
  getTextualAddition () {
    const textualAdditions = []
    const sortedActiveProductIds = this.isKDVVariety ? [this.activeKdvProductId] : [this.activeNsoProductId, this.activeVsoProductId]

    for (const id of sortedActiveProductIds) {
      const product = this.products.find(p => p.id === id && p.textualAddition)
      if (!product) {
        continue
      }

      if (product.serviceVariety === ServiceVarietyName.KDV) {
        textualAdditions.push(product.textualAddition)
      } else {
        const noteType = this.$t(`orderOverviewPage.productSelection.textualAddition${product.serviceVariety}`)
        const formattedDescription = `<b>${noteType}</b> ${product.textualAddition}`
        textualAdditions.push(formattedDescription)
      }
    }

    return textualAdditions
  }

  showTimeSelection (serviceVariety: ServiceVarietyName): boolean {
    if (serviceVariety === ServiceVarietyName.VSO) {
      return this.vsoProducts.length > 1
    } else if (serviceVariety === ServiceVarietyName.NSO) {
      return this.nsoProducts.length > 1
    }

    return this.products.length > 1
  }

  onProductTimeClicked (productId: string) {
    if (this.isKDVVariety) {
      this.activeKdvProductId = productId
    } else if (this.nsoProducts.find(p => p.id === productId)) {
      this.activeNsoProductId = productId
    } else if (this.vsoProducts.find(p => p.id === productId)) {
      this.activeVsoProductId = productId
    }

    this.updateSelectedProducts()
  }

  updateSelectedProducts (): void {
    const activeProductIds: string[] = []

    if (this.isKDVVariety && this.activeKdvProductId) {
      activeProductIds.push(this.activeKdvProductId)
    } else {
      if (this.activeNsoProductId) {
        activeProductIds.push(this.activeNsoProductId)
      }
      if (this.activeVsoProductId) {
        activeProductIds.push(this.activeVsoProductId)
      }
    }

    const currentSelection = this.getSelectedProductIds()
    const requireUpdate = currentSelection.length !== activeProductIds.length ||
      activeProductIds.reduce((requireUpdate: boolean, productId: string) => {
        return currentSelection.indexOf(productId) === -1 ? true : requireUpdate
      }, false)

    if (requireUpdate) {
      this.setSelectedProductIdsForServiceAsync(activeProductIds)
    }
  }
}
